<div class="max-width-1920-inner">
  <div>
    <div class="centered-text tablet-column tablet-left-text mobile-left-text">
      <h2>{{ $T.CustomerManagement | translate }}</h2>
      <h4>{{ $T.CustomerManagementDescription | translate }}</h4>
    </div>

    <div class="row row-gap-20 mobile-column tablet-column tablet-column-max-width-454">
      <div class="row tablet-row flex">
        <img alt="user" src="/assets/img/user.png" />
        <div>
          <h3>{{ $T.UserManagement | translate }}</h3>
          <ul>
            <li>{{ $T.UserManagementOptionCreateDeleteEditUsers | translate }}</li>
            <li>{{ $T.UserManagementOptionUsersInactive | translate }}</li>
          </ul>
        </div>
      </div>
      <div class="row tablet-row flex">
        <img alt="customer" src="/assets/img/customer.png" />
        <div>
          <h3>{{ $T.CustomerManagementHeadline | translate }}</h3>
          <ul>
            <li>{{ $T.CustomerManagementOptionCreateDeleteEditCustomer | translate }}</li>
            <li>{{ $T.CustomerManagementOptionCustomerInactive | translate }}</li>
            <li>{{ $T.CustomerManagementOptionInviteCustomer | translate }}</li>
          </ul>
        </div>
      </div>
      <div class="row tablet-row flex">
        <img alt="suborganisation" src="/assets/img/suborganisation.png" />
        <div>
          <h3>{{ $T.SuborganisationManagement | translate }}</h3>
          <ul>
            <li>{{ $T.SuborganisationManagementOptionCreateSuborganisations | translate }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
