import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { $T } from 'src/assets/i18n/Translation-keys';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule, MatIconModule, FlexLayoutModule],
  selector: 'app-platform-section',
  templateUrl: './platform-section.component.html',
  styleUrls: ['./platform-section.component.scss'],
})
export class PlatformSectionComponent {
  $T = $T;
}
