import { Component, OnInit } from '@angular/core';
import { SidenavComponent } from '../../sidenav/sidenav.component';
import { FooterSectionSharingComponent } from '../landing-page/footer-section-sharing/footer-section-sharing.component';
import { FooterSectionSocialComponent } from '../landing-page/footer-section-social/footer-section-social.component';
import { FooterSectionComponent } from '../landing-page/footer-section/footer-section.component';
import { HowToSectionComponent } from '../landing-page/how-to-section/how-to-section.component';
import { InformationSectionComponent } from '../landing-page/information-section/information-section.component';

@Component({
  standalone: true,
  imports: [
    SidenavComponent,
    HowToSectionComponent,
    FooterSectionComponent,
    FooterSectionSocialComponent,
    FooterSectionSharingComponent,
    InformationSectionComponent,
  ],
  selector: 'app-connect-devices-page',
  templateUrl: './connect-devices.page.html',
  styleUrls: ['./connect-devices.page.scss'],
  providers: [],
})
export class ConnectDevicesPage implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
