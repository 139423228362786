<app-sidenav active="st-terms-of-use" containerClass="content-container">
  <div class="max-width-1920-inner" id="first">
    <div class="column centered-text">
      <h2>{{'TermsOfUse' | translate}}</h2>
      <p>
        {{'Of' | translate}}<br />
        W&H Dentalwerk Bürmoos GmbH <br />
        Ignaz-Glaser-Str. 53 <br />
        A-5111 Bürmoos <br />
        ({{'Licensor' | translate | split: 0}}
        <b>{{'Licensor' | translate | split: 1}}</b>)
      </p>
      <p>{{'ServiceToolUsage' | translate}}</p>
    </div>
    <div>
      <h3>I. {{'ServiceToolTermsOfUse10' | translate}}</h3>
      <p>1. {{'ServiceToolTermsOfUse11' | translate}}</p>
      <p>2. {{'ServiceToolTermsOfUse12' | translate}}</p>
      <p>3. {{'ServiceToolTermsOfUse13' | translate}}</p>
      <p>
        4. {{'ServiceToolTermsOfUse14' | translate | split: 0}}
        <b>{{'ServiceToolTermsOfUse14' | translate | split: 1}}</b>
        {{'ServiceToolTermsOfUse14' | translate | split: 2}}
        <b>{{'ServiceToolTermsOfUse14' | translate | split: 3}}</b>
        {{'ServiceToolTermsOfUse14' | translate | split: 4}}
      </p>
    </div>
    <div>
      <h3>II. {{'ServiceToolTermsOfUse20' | translate}}</h3>
      <p>
        1. {{'ServiceToolTermsOfUse21' | translate | split: 0}}
        <b>{{'ServiceToolTermsOfUse21' | translate | split: 1}}</b>
        {{'ServiceToolTermsOfUse21' | translate | split: 2}}
        <b>{{'ServiceToolTermsOfUse21' | translate | split: 3}}</b>
        {{'ServiceToolTermsOfUse21' | translate | split: 4}}
        <b>{{'ServiceToolTermsOfUse21' | translate | split: 5}}</b>
        {{'ServiceToolTermsOfUse21' | translate | split: 6}}
      </p>
      <p>
        2. {{'ServiceToolTermsOfUse22' | translate | split: 0}}
        <b>{{'ServiceToolTermsOfUse22' | translate | split: 1}}</b>
        {{'ServiceToolTermsOfUse22' | translate | split: 2}}
      </p>
      <p>
        3. {{'ServiceToolTermsOfUse23' | translate | split: 0}}
        <a href="https://iodent.com/ST/scope-of-service">{{'ServiceToolTermsOfUse23' | translate | split: 1}}</a>
        {{'ServiceToolTermsOfUse23' | translate | split: 2}}
        <b>{{'ServiceToolTermsOfUse23' | translate | split: 3}}</b>
        {{'ServiceToolTermsOfUse23' | translate | split: 4}}
      </p>
      <p>
        4. {{'ServiceToolTermsOfUse24' | translate | split: 0}}
        <b>{{'ServiceToolTermsOfUse24' | translate | split: 1}}</b>
        {{'ServiceToolTermsOfUse24' | translate | split: 2}}
      </p>
      <p>5. {{'ServiceToolTermsOfUse25' | translate}}</p>
    </div>
    <div>
      <h3>III. {{'ServiceToolTermsOfUse30' | translate}}</h3>
      <p>
        1. {{'ServiceToolTermsOfUse31' | translate | split: 0}}
        <b>{{'ServiceToolTermsOfUse31' | translate | split: 1}}</b>
        {{'ServiceToolTermsOfUse31' | translate | split: 2}}
      </p>
      <p>
        2. {{'ServiceToolTermsOfUse32' | translate | split: 0}}
        <b>{{'ServiceToolTermsOfUse32' | translate | split: 1}}</b>
        {{'ServiceToolTermsOfUse32' | translate | split: 2}}
      </p>
      <p>3. {{'ServiceToolTermsOfUse33' | translate}}</p>
      <p>4. {{'ServiceToolTermsOfUse34' | translate}}</p>
      <p>5. {{'ServiceToolTermsOfUse35' | translate}}</p>
      <p>6. {{'ServiceToolTermsOfUse36' | translate}}</p>
    </div>
    <div>
      <h3>IV. {{'ServiceToolTermsOfUse40' | translate}}</h3>
      <p>1. {{'ServiceToolTermsOfUse41' | translate}}</p>
      <p>
        2. {{'ServiceToolTermsOfUse42' | translate | split: 0}}
        <b>{{'ServiceToolTermsOfUse42' | translate | split: 1}}</b>
        {{'ServiceToolTermsOfUse42' | translate | split: 2}}
      </p>
      <p>
        3. {{'ServiceToolTermsOfUse43' | translate | split: 0}}
        <b>{{'ServiceToolTermsOfUse43' | translate | split: 1}}</b>
        {{'ServiceToolTermsOfUse43' | translate | split: 2}}
      </p>
      <p>4. {{'ServiceToolTermsOfUse44' | translate}}</p>
    </div>
    <div>
      <h3>V. {{'ServiceToolTermsOfUse50' | translate}}</h3>
      <p>{{'ServiceToolTermsOfUse51' | translate}}</p>
    </div>
    <div>
      <h3>VI. {{'ServiceToolTermsOfUse60' | translate}}</h3>
      <p>1. {{'ServiceToolTermsOfUse61' | translate}}</p>
      <p>
        2. {{'ServiceToolTermsOfUse62' | translate | split: 0}}
        <b>{{'ServiceToolTermsOfUse62' | translate | split: 1}}</b>
        {{'ServiceToolTermsOfUse62' | translate | split: 2}}
        <b>{{'ServiceToolTermsOfUse62' | translate | split: 3}}</b>
        {{'ServiceToolTermsOfUse62' | translate | split: 4}}
      </p>
      <p>3. {{'ServiceToolTermsOfUse63' | translate}}</p>
      <p>4. {{'ServiceToolTermsOfUse64' | translate}}</p>
    </div>
    <div>
      <h3>VII. {{'ServiceToolTermsOfUse70' | translate}}</h3>
      <p>
        1. {{'ServiceToolTermsOfUse71' | translate | split: 0}}
        <a href="https://iodent.com/ST/scope-of-service">{{'ServiceToolTermsOfUse71' | translate | split: 1}}</a>
        {{'ServiceToolTermsOfUse71' | translate | split: 2}}
      </p>

      <p>2. {{'ServiceToolTermsOfUse72' | translate}}</p>
      <p>
        3. {{'ServiceToolTermsOfUse73' | translate | split: 0}}
        <b>{{'ServiceToolTermsOfUse73' | translate | split: 1}}</b>
        {{'ServiceToolTermsOfUse73' | translate | split: 2}}
      </p>
      <p>
        4. {{'ServiceToolTermsOfUse74' | translate | split: 0}}
        <a href="https://iodent.com/ST/scope-of-service">{{'ServiceToolTermsOfUse74' | translate | split: 1}}</a>
        {{'ServiceToolTermsOfUse74' | translate | split: 2}}
      </p>
      <p>
        5. {{'ServiceToolTermsOfUse75' | translate | split: 0}}
        <b>{{'ServiceToolTermsOfUse75' | translate | split: 1}}</b>
        {{'ServiceToolTermsOfUse75' | translate | split: 2}}
      </p>
      <p>
        6. {{'ServiceToolTermsOfUse76' | translate | split: 0}}
        <b>{{'ServiceToolTermsOfUse76' | translate | split: 1}}</b>
        {{'ServiceToolTermsOfUse76' | translate | split: 2}}
      </p>
      <p>7. {{'ServiceToolTermsOfUse77' | translate}}</p>
    </div>
    <div>
      <h3>VIII. {{'ServiceToolTermsOfUse80' | translate}}</h3>
      <p>1. {{'ServiceToolTermsOfUse81' | translate}}</p>
      <p>2. {{'ServiceToolTermsOfUse82' | translate}}</p>
      <p>
        3. {{'ServiceToolTermsOfUse83' | translate | split: 0}}
        <b>{{'ServiceToolTermsOfUse83' | translate | split: 1}}</b>
        {{'ServiceToolTermsOfUse83' | translate | split: 2}}
      </p>
      <p>4. {{'ServiceToolTermsOfUse84' | translate}}</p>
    </div>
    <div>
      <h3>IX. {{'ServiceToolTermsOfUse90' | translate}}</h3>
      <p>1. {{'ServiceToolTermsOfUse91' | translate}}</p>
      <p>2. {{'ServiceToolTermsOfUse92' | translate}}</p>
    </div>
    <div>
      <h3>X. {{'ServiceToolTermsOfUse100' | translate}}</h3>
      <p>{{'ServiceToolTermsOfUse101' | translate}}</p>
    </div>
    <div>
      <h3>XI. {{'ServiceToolTermsOfUse110' | translate}}</h3>
      <p>1. {{'ServiceToolTermsOfUse111' | translate}}</p>
      <p>2. {{'ServiceToolTermsOfUse112' | translate}}</p>
      <p>3. {{'ServiceToolTermsOfUse113' | translate}}</p>
      <p>4. {{'ServiceToolTermsOfUse114' | translate}}</p>
      <p>5. {{'ServiceToolTermsOfUse115' | translate}}</p>
      <p>6. {{'ServiceToolTermsOfUse116' | translate}}</p>
      <p>7. {{'ServiceToolTermsOfUse117' | translate}}</p>
      <p>8. {{'ServiceToolTermsOfUse118' | translate}}</p>
    </div>
    <div class="centered-text version">
      <p>{{'VersionToUServiceTool' | translate}}</p>
    </div>
  </div>
</app-sidenav>
