<div class="navbar">
  <mat-toolbar [ngClass]="hover" (mouseover)="hover = 'hover'" class="mat-elevation-z0 first">
    <div class="uk-container">
      <div fxFlex fxLayoutAlign="start center">
        <a [routerLink]="['/']" class="logo">
          <mat-icon svgIcon="iodent-logo"></mat-icon>
        </a>
        <a class="link" href="https://www.wh.com">{{ 'ToWH' | translate }}</a>
        <span class="spacer"></span>
        <div class="auto-width">
          <mat-form-field class="language-select" subscriptSizing="dynamic">
            <mat-select
              (selectionChange)="onLanguageChange()"
              [(value)]="selectedLanguage"
              disableOptionCentering
              panelClass="language-select-panel">
              <mat-option *ngFor="let language of languages" [value]="language.language">
                {{ language.languageText }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <button (click)="onLogin()" class="login" color="primary" mat-stroked-button>{{ 'LogIn' | translate }}</button>
        <button (click)="onRegister()" class="register white-text" color="accent" mat-flat-button>
          {{ 'Register' | translate }}
        </button>
        <span (click)="toggle(sidenav)" class="header__hamburger-wrapper open-drawer" data-ivue="2" id="toggle">
          <span class="header__hamburger uk-display-inline-block">
            <div [ngClass]="{ 'header__hamburger-menu--active': sidenav.opened }" class="header__hamburger-menu"></div>
          </span>
        </span>
      </div>
    </div>
  </mat-toolbar>
  <mat-toolbar (mouseout)="mouseoutToolbar($event)" class="navigation max-width-1920">
    <div class="uk-container">
      <div class="nav-items">
        <button [ngClass]="{ active: active === 'home' }" [routerLink]="['/']" class="nav-item size-18" mat-button>
          {{ 'Home' | translate }}
          <span class="slider"></span>
        </button>
        <button
          [ngClass]="{ active: active === 'connect-devices' }"
          [routerLink]="['/connect-devices']"
          class="nav-item size-18"
          mat-button>
          {{ 'ConnectDevices' | translate }}
          <span class="slider"></span>
        </button>
      </div>
    </div>
  </mat-toolbar>
  <span class="hover-spacer"></span>
</div>
