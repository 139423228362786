<div class="max-width-1920-inner">
  <div class="row mobile-column tablet-column">
    <div class="tablet-column mobile-column flex flex-07">
      <h2>{{ $T.DeviceManagement | translate }}</h2>
      <p class="spacer">{{ $T.DeviceManagementDescription | translate }}</p>
      <ul class="inline-block">
        <li>{{ $T.ConnectionState | translate }}</li>
        <li>{{ $T.ConsumablesInformation | translate }}</li>
        <li>{{ $T.DeviceNameConfiguration | translate }}</li>
        <li>{{ $T.FilterAndSearch | translate }}</li>
        <li>{{ $T.CustomerInformation | translate }}</li>
        <li>{{ $T.DocumentStorage | translate }}</li>
      </ul>
    </div>
    <div class="tablet-column flex position-relative">
      <div class="image-container image-container-left">
        <img alt="Devices" src="/assets/img/Laptop-old.png" />
      </div>
    </div>
  </div>
</div>
