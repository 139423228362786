import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { $T } from 'src/assets/i18n/Translation-keys';
import { SplitPipeModule } from '../../pipes';
import { SidenavComponent } from '../../sidenav/sidenav.component';
import { ToolbarComponent } from '../../toolbar/toolbar.component';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const dr_webcare_privacynotice;

@Component({
  standalone: true,
  imports: [
    ToolbarComponent,
    MatIconModule,
    RouterModule,
    MatSidenavModule,
    TranslateModule,
    MatListModule,
    CommonModule,
    SplitPipeModule,
    MatToolbarModule,
    MatSelectModule,
    FlexLayoutModule,
    MatButtonModule,
    SidenavComponent,
  ],
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.page.html',
  styleUrls: ['./privacy-policy.page.scss'],
})
export class PrivacyPolicyPage implements OnInit {
  $T = $T;

  ngOnInit(): void {
    dr_webcare_privacynotice.integrate();
  }
}
