<div class="max-width-1920-inner">
  <div>
    <div class="centered-text tablet-column tablet-left-text mobile-left-text">
      <h2>{{ 'CustomerManagement' | translate }}</h2>
      <h4>{{ 'CustomerManagementDescription' | translate }}</h4>
    </div>

    <div class="row row-gap-20 mobile-column tablet-column tablet-column-max-width-454">
      <div class="row tablet-row flex">
        <img alt="user" src="/assets/img/user.png" />
        <div>
          <h3>{{ 'UserManagement' | translate }}</h3>
          <ul>
            <li>{{ 'UserManagementOptionCreateDeleteEditUsers' | translate }}</li>
            <li>{{ 'UserManagementOptionUsersInactive' | translate }}</li>
          </ul>
        </div>
      </div>
      <div class="row tablet-row flex">
        <img alt="customer" src="/assets/img/customer.png" />
        <div>
          <h3>{{ 'CustomerManagementHeadline' | translate }}</h3>
          <ul>
            <li>{{ 'CustomerManagementOptionCreateDeleteEditCustomer' | translate }}</li>
            <li>{{ 'CustomerManagementOptionCustomerInactive' | translate }}</li>
            <li>{{ 'CustomerManagementOptionInviteCustomer' | translate }}</li>
          </ul>
        </div>
      </div>
      <div class="row tablet-row flex">
        <img alt="suborganisation" src="/assets/img/suborganisation.png" />
        <div>
          <h3>{{ 'SuborganisationManagement' | translate }}</h3>
          <ul>
            <li>{{ 'SuborganisationManagementOptionCreateSuborganisations' | translate }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
