<div class="max-width-1920-inner">
  <div>
    <div class="centered-text tablet-left-text mobile-left-text">
      <h2>{{ $T.IntelligentAssist | translate }}</h2>
    </div>
  </div>
  <div class="column">
    <mat-card class="flex tablet-width-50-margin-20 column">
      <mat-card-header>
        <mat-card-title>
          <h3>{{ $T.RemoteUpdate | translate }}</h3>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <ul>
          <li>{{ $T.RemoteUpdateOptionEmailNotification | translate }}</li>
          <li>{{ $T.RemoteUpdateOptionChangelog | translate }}</li>
          <li>{{ $T.RemoteUpdateOptionTriggerRemote | translate }}</li>
          <li>{{ $T.RemoteUpdateOptionFeedback | translate }}</li>
        </ul>
      </mat-card-content>
    </mat-card>
    <mat-card class="flex tablet-width-50-margin-20">
      <mat-card-header>
        <mat-card-title>
          <h3>{{ $T.CustomAlarms | translate }}</h3>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <ul>
          <li>
            {{ $T.CustomAlarmsOptionConfigurableNotification | translate }}
            <ul>
              <li>{{ $T.CustomAlarmsOptionConfigurableNotificationError | translate }}</li>
              <li>{{ $T.CustomAlarmsOptionConfigurableNotificationTicket | translate }}</li>
              <li>{{ $T.CustomAlarmsOptionConfigurableNotificationConsumables | translate }}</li>
              <li>{{ $T.CustomAlarmsOptionConfigurableNotificationService | translate }}</li>
              <li>{{ $T.CustomAlarmsOptionConfigurableNotificationOffline | translate }}</li>
            </ul>
          </li>
          <li>{{ $T.CustomAlarmsOptionCustomizedAlarms | translate }}</li>
          <li>{{ $T.CustomAlarmsOptionVacuumTest | translate }}</li>
        </ul>
      </mat-card-content>
    </mat-card>

    <mat-card class="flex tablet-width-50-margin-20">
      <mat-card-header>
        <mat-card-title>
          <h3>{{ $T.ErrorDashboard | translate }}</h3>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <ul>
          <li>{{ $T.ErrorDashboardOptionFiltering | translate }}</li>
          <li>{{ $T.ErrorDashboardOptionLiveUpdate | translate }}</li>
          <li>{{ $T.ErrorDashboardOptionSolutionSuggestion | translate }}</li>
          <li>{{ $T.ErrorDashboardOptionDocumentUpload | translate }}</li>
          <li>{{ $T.ErrorDashboardOptionDownloadRemotely | translate }}</li>
        </ul>
      </mat-card-content>
    </mat-card>
  </div>
</div>
