<div class="max-width-1920-inner">
  <div class="row mobile-column tablet-column">
    <div class="tablet-column mobile-column flex flex-07">
      <h2>{{ $T.FeedbackHeadline | translate }}</h2>
      <p class="spacer">{{ $T.FeedbackText | translate }}</p>
    </div>
    <div class="tablet-column flex position-relative mobile-column">
      <div class="image-container centered-text">
        <mat-icon>feedback</mat-icon>
      </div>
    </div>
  </div>
</div>
