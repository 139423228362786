import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslateModule, MatButtonModule],
  selector: 'app-how-to-section-step',
  templateUrl: './how-to-section-step.component.html',
  styleUrls: ['./how-to-section-step.component.scss'],
})
export class HowToSectionStepComponent implements OnInit {
  @Input() iconPath: string;
  @Input() step: string;
  @Input() text: string;

  constructor() {}

  ngOnInit(): void {}
}
