<div class="max-width-1920-inner">
  <div class="row mobile-column tablet-column">
    <div class="tablet-column mobile-column flex flex-1-5">
      <h2>{{ $T.ServiceToolOverviewHeadline | translate }}</h2>
      <p>{{ $T.ServiceToolOverviewDescription1 | translate }}</p>
      <p>{{ $T.ServiceToolOverviewDescription2 | translate }}</p>
      <p>{{ $T.ServiceToolOverviewDescription3 | translate }}</p>
      <ul>
        <li>{{ $T.RepairDrawing | translate }}</li>
        <li>{{ $T.SparePartDrawing | translate }}</li>
        <li>{{ $T.TechInfo | translate }}</li>
        <li>{{ $T.GeneralServiceDocuments | translate }}</li>
      </ul>
    </div>
    <div class="tablet-column flex position-relative">
      <div class="image-container">
        <img alt="technician" src="/assets/img/servicetool.jpg" />
      </div>
    </div>
  </div>
</div>
