<div class="max-width-1920-inner">
  <div>
    <div class="centered-text tablet-left-text mobile-left-text">
      <h2>{{ 'SupportedDevices' | translate }}</h2>
    </div>
    <div class="row row-gap-20 mobile-column tablet-column">
      <mat-card class="tablet-column flex">
        <mat-card-content>
          <div class="centered-text">
            <img class="icon" alt="implantmed" src="/assets/img/implantmed.jpg" />
            <h3>{{ 'DeviceCategory1' | translate }}</h3>
          </div>

          <div class="row flex">
            <div class="row flex row-gap-20 mobile-column">
              <ul class="flex">
                <li class="row" ngClass.lt-md="mobile">
                  <div class="device-text" ngClass.lt-md="mobile">Fußsteuerung S-NW</div>
                  <div class="device-ref" ngClass.lt-md="mobile">30264000</div>
                </li>
                <li>
                  <div>Dongle für S-NW</div>
                  <div>07759700</div>
                </li>
                <li>
                  <div>Fußsteuerung S-N2</div>
                  <div>30285000</div>
                </li>
                <li>
                  <div>SI-923 Implantmed</div>
                  <div>30286000</div>
                </li>
                <li class="row" ngClass.lt-md="mobile">
                  <div>SI-915 Implantmed</div>
                  <div>30287000</div>
                </li>
              </ul>
              <ul class="flex">
                <li>
                  <div>SI-1023 Implantmed</div>
                  <div>30288000</div>
                </li>
                <li>
                  <div>SI-1015 Implantmed</div>
                  <div>30289000</div>
                </li>
                <li>
                  <div>SI-1010 Implantmed</div>
                  <div>30290000</div>
                </li>
                <li>
                  <div>SA-435 M Piezomed Modul Plus</div>
                  <div>30406000</div>
                </li>
                <li>
                  <div>SA-430 M Piezomed Modul Classic</div>
                  <div>30407000</div>
                </li>
              </ul>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="row row-gap-20 mobile-column tablet-column">
      <mat-card class="tablet-column flex">
        <mat-card-content>
          <div class="centered-text">
            <img class="icon" alt="pl-40" src="/assets/img/pl-40.jpg" />
            <h3>{{ 'DeviceCategory2' | translate }}</h3>
          </div>

          <div class="row flex mobile-column">
            <ul class="flex">
              <li>
                <div>Fußsteuerung C-NW</div>
                <div>30316000</div>
              </li>
              <li>
                <div>PL-40 H Proxeo Twist System</div>
                <div>30317000</div>
              </li>
              <li>
                <div>PB-510 Proxeo Ultra</div>
                <div>30323000</div>
              </li>
              <li>
                <div>PB-520 Proxeo Ultra</div>
                <div>30324000</div>
              </li>
              <li>
                <div>PB-530 Proxeo Ultra</div>
                <div>30325000</div>
              </li>
              <li>
                <div>PL-40 HW Proxeo Twist Antrieb</div>
                <div>30410000</div>
              </li>
            </ul>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="tablet-column flex">
        <mat-card-content>
          <div class="centered-text">
            <img class="icon" alt="assistina" src="/assets/img/assistina.jpg" />
            <h3 class="backslash-n-brake">{{ 'DeviceCategory3' | translate }}</h3>
          </div>

          <div class="row flex mobile-column">
            <ul class="flex">
              <li>
                <div>MB-302 Assistina Twin</div>
                <div>30310000</div>
              </li>
              <li>
                <div>MB-302 Assistina Twin (+ Quick ISO)</div>
                <div>30310001</div>
              </li>
              <li>
                <div>MB-302 Assistina Twin (+ Quick RM)</div>
                <div>30310002</div>
              </li>
              <li>
                <div>MB-302 Assistina Twin (+ Quick ISO, RM)</div>
                <div>30310003</div>
              </li>
            </ul>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="row row-gap-20 mobile-column tablet-column">
      <mat-card class="tablet-column flex">
        <mat-card-content>
          <div class="centered-text">
            <img class="icon" alt="af-100" src="/assets/img/af-100.jpg" />
            <h3 class="backslash-n-brake">{{ 'DeviceCategory4' | translate }}</h3>
          </div>

          <div class="row flex mobile-column">
            <ul class="flex">
              <li>
                <div>MF-100</div>
                <div>30241000</div>
              </li>
              <li>
                <div>AF-100</div>
                <div>30242000</div>
              </li>
            </ul>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="tablet-column flex">
        <mat-card-content>
          <div class="centered-text">
            <img class="icon" alt="amadeo" src="/assets/img/amadeo.jpg" />
            <h3>{{ 'DeviceCategory5' | translate }}</h3>
          </div>

          <div class="row flex mobile-column">
            <ul class="flex">
              <li>
                <div>M-UK1023 Amadeo</div>
                <div>30387000</div>
              </li>
              <li>
                <div>M-UK1015 Amadeo</div>
                <div>30388000</div>
              </li>
              <li>
                <div>M-UK1010 Amadeo</div>
                <div>30389000</div>
              </li>
            </ul>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="row row-gap-20 mobile-column tablet-column">
      <mat-card class="tablet-column flex">
        <mat-card-content>
          <div class="centered-text">
            <img class="icon" alt="builtin" src="/assets/img/builtin.jpg" />
            <h3>{{ 'DeviceCategory6' | translate }}</h3>
          </div>

          <div class="row flex">
            <div class="row flex row-gap-20 mobile-column">
              <ul class="flex">
                <li>
                  <div>MC-2.0 E W&H Digital Motorcontroller Endo</div>
                  <div>30182002</div>
                </li>
                <li>
                  <div>AC-1.0</div>
                  <div>30223000</div>
                </li>
                <li>
                  <div>D-2.0 Display Basic Einheitenmotor</div>
                  <div>30243000</div>
                </li>
                <li>
                  <div>D-3.0</div>
                  <div>30244000</div>
                </li>
                <li>
                  <div>Fußsteuerung S-NW CAN-Bus</div>
                  <div>30264003</div>
                </li>
                <li>
                  <div>DM39-DC1</div>
                  <div>30274000</div>
                </li>
                <li>
                  <div>MC-1.0 & AD-1.0 Analog Motorcontroller R&P</div>
                  <div>30278000</div>
                </li>
                <li>
                  <div>MC-1.0 E & AD-1.0 Analog Motorcontroller Endo</div>
                  <div>30279000</div>
                </li>
                <li>
                  <div>AD-1.0 vergossen Analog-Digital</div>
                  <div>30282000</div>
                </li>
                <li>
                  <div>D-2.0 E Display Basic Einheitenmotor Endo</div>
                  <div>30283000</div>
                </li>
                <li>
                  <div>D-2.0 A Display Basic Advanced Air</div>
                  <div>30284000</div>
                </li>
              </ul>
              <ul class="flex">
                <li>
                  <div>Fußsteuerung S-N2 CAN-Bus</div>
                  <div>30285002</div>
                </li>
                <li>
                  <div>PC-1.0</div>
                  <div>30294000</div>
                </li>
                <li>
                  <div>PC-1.0 SW-Variante</div>
                  <div>30294001</div>
                </li>
                <li>
                  <div>MC-2.0 W&H Digital Motorcontroller R&P</div>
                  <div>30311000</div>
                </li>
                <li>
                  <div>EA-53 TT</div>
                  <div>30312000</div>
                </li>
                <li>
                  <div>APP-HC-03-XX-00</div>
                  <div>30344000</div>
                </li>
                <li>
                  <div>EXP-CC-XX-00</div>
                  <div>30345000</div>
                </li>
                <li>
                  <div>DD-1.0</div>
                  <div>30349000</div>
                </li>
                <li>
                  <div>MC-2.0 I</div>
                  <div>30353000</div>
                </li>
                <li>
                  <div>EXP-PD-ST-00</div>
                  <div>30363000</div>
                </li>
                <li>
                  <div>D-3.0-MMI (Chair System)</div>
                  <div>30366000</div>
                </li>
                <li>
                  <div>APP-MC-01-RP-00</div>
                  <div>30412000</div>
                </li>
              </ul>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
