<div class="max-width-1920-inner">
  <div class="column">
    <div class="row row-gap-20 tablet-column-max-width-454 mobile-wrap">
      <a class="flex social-link" href="https://www.facebook.com/whdental/">
        <mat-icon svgIcon="facebook"></mat-icon>
        <div class="link">Facebook</div>
      </a>
      <a class="flex social-link" href="https://www.linkedin.com/company/w&h/">
        <mat-icon svgIcon="linkedin"></mat-icon>
        <div class="link">LinkedIn</div>
      </a>
      <a class="flex social-link" href="https://www.instagram.com/whdentalwerk/">
        <mat-icon class="instagram" svgIcon="instagram"></mat-icon>
        <div class="link">Instagram</div>
      </a>
      <a class="flex social-link" href="https://www.youtube.com/user/whdentalwerk">
        <mat-icon svgIcon="youtube"></mat-icon>
        <div class="link">YouTube</div>
      </a>
    </div>

    <div class="centered-text wh-link">
      <a class="link" href="https://www.wh.com/"> wh.com </a>
    </div>

    <div class="centered-text mobile-column">
      <a [routerLink]="['/privacy-policy']" class="flex link">{{ $T.LegalInfoPrivacyStatement | translate }}</a>
      <a [routerLink]="['/scope-of-service']" class="flex link">{{ $T.ScopeOfServices | translate }}</a>
      <a [routerLink]="['/terms-of-use']" class="flex link">{{ $T.TermsOfUse | translate }}</a>
    </div>
  </div>
</div>
