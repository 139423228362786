<div class="max-width-1920-inner">
  <div class="row mobile-column tablet-column">
    <div class="tablet-column mobile-column flex flex-07">
      <h2 [innerHTML]="$T.HeaderHeadline | translate"></h2>
      <p [innerHTML]="$T.PlatformDescription1 | translate" class="spacer"></p>
      <p [innerHTML]="$T.PlatformDescription2 | translate" class="spacer"></p>

      <h4>{{ $T.AvailableLanguages | translate }}</h4>

      <ul class="inline-block">
        <li>{{ $T.English | translate }}</li>
        <li>{{ $T.Italian | translate }}</li>
        <li>{{ $T.German | translate }}</li>
      </ul>
    </div>
    <div class="tablet-column flex position-relative">
      <div class="image-container">
        <img alt="Devices" src="/assets/img/Devices-old.png" />
      </div>
    </div>
  </div>
</div>
