<div class="max-width-1920-inner">
  <div>
    <div class="centered-text tablet-left-text mobile-left-text">
      <h2>{{ 'SystemRequirements' | translate }}</h2>
    </div>
    <ul>
      <li>
        <a href="/scope-of-service#supported">{{ 'SystemRequirementsOption1' | translate }}</a>
      </li>
      <li>{{ 'SystemRequirementsOption2' | translate }}</li>
      <li>{{ 'SystemRequirementsOption3' | translate }}</li>
      <li>{{ 'SystemRequirementsOption4' | translate }}</li>
    </ul>
  </div>
</div>
