<div class="max-width-1920-inner">
  <div class="column centered-text">
    <h2>{{ $T.HowTo | translate }}</h2>
    <h4 [innerHTML]="$T.HowToText | translate" class="bold"></h4>
  </div>

  <div class="row row-wrap row-center">
    <app-how-to-section-step
      [text]="$T.HowTo1 | translate"
      iconPath="/assets/icons/device-lisa2015-3d.svg"
      step="1."></app-how-to-section-step>
    <app-how-to-section-step [text]="$T.HowTo2 | translate" iconPath="/assets/img/qrcode.png" step="2."></app-how-to-section-step>
    <app-how-to-section-step [text]="$T.HowTo3 | translate" iconPath="/assets/img/Laptop.png" step="3."></app-how-to-section-step>
    <app-how-to-section-step [text]="$T.HowTo4 | translate" iconPath="/assets/icons/DM_3D.svg" step="4."></app-how-to-section-step>
    <app-how-to-section-step
      [text]="$T.HowTo5 | translate"
      iconPath="/assets/icons/installationguide_icons_5.svg"
      step="5."></app-how-to-section-step>
    <app-how-to-section-step
      [text]="$T.HowTo6 | translate"
      iconPath="/assets/icons/installationguide_icons_6.svg"
      step="6."></app-how-to-section-step>
  </div>

  <div class="mobile-column centered-text">
    <button (click)="onDownloadFlyer()" mat-stroked-button>
      <mat-icon svgIcon="download"></mat-icon>
      {{ $T.PDFHowToDownload | translate }}
    </button>
  </div>
</div>
