<div class="max-width-1920-inner">
  <div>
    <div class="centered-text tablet-left-text mobile-left-text">
      <h2>{{ $T.SystemRequirements | translate }}</h2>
    </div>
    <ul>
      <li>
        <a href="/scope-of-service#supported">{{ $T.SystemRequirementsOption1 | translate }}</a>
      </li>
      <li>{{ $T.SystemRequirementsOption2 | translate }}</li>
      <li>{{ $T.SystemRequirementsOption3 | translate }}</li>
      <li>{{ $T.SystemRequirementsOption4 | translate }}</li>
    </ul>
  </div>
</div>
