import { CommonModule } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from 'ngx-flexible-layout';

@Component({
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslateModule, MatButtonModule, FlexLayoutModule, RouterModule],
  selector: 'app-footer-section-sharing',
  templateUrl: './footer-section-sharing.component.html',
  styleUrls: ['./footer-section-sharing.component.scss'],
})
export class FooterSectionSharingComponent {
  showScrollTopOverlay = false;

  scrollTop() {
    const top = document.getElementsByTagName('body')[0] as HTMLElement;
    top.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }

  isInViewport(element: HTMLElement): boolean {
    let triggerPosition = 150;
    const isMobile = document.getElementsByClassName('size-mobile')[0] as HTMLElement;
    if (isMobile) {
      triggerPosition = 80;
    }

    const topElement = document.getElementById('first') as HTMLElement;
    const hasScrolledDown = topElement.getBoundingClientRect().top < triggerPosition;
    const hasScrolledBy = element.getBoundingClientRect().top - (window.innerHeight || document.documentElement.clientHeight) < 0;
    let showOverlay = false;
    if (hasScrolledBy) {
      showOverlay = false;
    } else if (hasScrolledDown) {
      showOverlay = true;
    }

    return showOverlay;
  }

  onScroll() {
    const scrollTop = document.getElementById('scrollTop') as HTMLElement;
    if (scrollTop) {
      this.showScrollTopOverlay = this.isInViewport(scrollTop);
    }
  }

  @HostListener('document:mousewheel', ['$event'])
  onScrollEvent() {
    this.onScroll();
  }
  @HostListener('document:touchmove', ['$event'])
  onTouchmove() {
    this.onScroll();
  }
  @HostListener('document:touchend', ['$event'])
  onTouchend() {
    setTimeout(() => {
      this.onScroll();
    }, 2000);
  }
}
