import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { redirectToRegistration } from '../../../app-redirection/redirection';

@Component({
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslateModule, MatButtonModule, FlexLayoutModule],
  selector: 'app-header-section',
  templateUrl: './header-section.component.html',
  styleUrls: ['./header-section.component.scss'],
})
export class HeaderSectionComponent {
  onRegister = redirectToRegistration;
}
