<div class="flex">
  <div class="mat-icon-container">
    <mat-icon class="bg" svgIcon="esagono"> </mat-icon>
    <img class="icon" alt="bg" src="{{ iconPath }}" />
    <mat-icon [attr.data-content]="step" class="number" svgIcon="esagono"> </mat-icon>
  </div>
  <div class="centered-text">
    <p [innerHTML]="text | translate"></p>
  </div>
</div>
