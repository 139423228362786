<app-sidenav active="st-scope-of-service">
  <div class="centered-text" id="first">
    <h1>{{ $T.ScopeOfServices | translate }}</h1>
  </div>
  <div class="overview-section">
    <app-overview-section></app-overview-section>
  </div>
  <div class="error-readout-section">
    <app-error-readout-section></app-error-readout-section>
  </div>
  <div class="singlefunctiontest-section">
    <app-singlefunctiontest-section></app-singlefunctiontest-section>
  </div>
  <div class="history-section">
    <app-history-section></app-history-section>
  </div>
  <div class="endtest-checklist-section">
    <app-endtest-checklist-section></app-endtest-checklist-section>
  </div>
  <div class="feedback-section">
    <app-feedback-section></app-feedback-section>
  </div>
  <div class="supported-section">
    <app-supported-devices-section></app-supported-devices-section>
  </div>
  <div class="system-section">
    <app-system-requirements-section></app-system-requirements-section>
  </div>
</app-sidenav>
