<div class="max-width-1920-inner">
  <div class="row mobile-column tablet-column">
    <div class="tablet-column mobile-column flex flex-07">
      <h2>{{ 'DeviceManagement' | translate }}</h2>
      <p class="spacer">{{ 'DeviceManagementDescription' | translate }}</p>
      <ul class="inline-block">
        <li>{{ 'ConnectionState' | translate }}</li>
        <li>{{ 'ConsumablesInformation' | translate }}</li>
        <li>{{ 'DeviceNameConfiguration' | translate }}</li>
        <li>{{ 'FilterAndSearch' | translate }}</li>
        <li>{{ 'CustomerInformation' | translate }}</li>
        <li>{{ 'DocumentStorage' | translate }}</li>
      </ul>
    </div>
    <div class="tablet-column flex position-relative">
      <div class="image-container image-container-left">
        <img alt="Devices" src="/assets/img/Laptop-old.png" />
      </div>
    </div>
  </div>
</div>
