<div class="max-width-1920-inner">
  <div>
    <div class="centered-text">
      <h2>{{ 'ServicesHeadline' | translate }}</h2>
      <h4 class="backslash-n-brake">{{ 'ServicesText' | translate }}</h4>
    </div>

    <div class="row row-gap-20 mobile-column tablet-wrap tablet-wrap-margin-20">
      <mat-card appearance="outlined" class="tablet-width-50-margin-20 centered-text column">
        <div class="flex">
          <img alt="IntelligentAssist" src="/assets/img/intelligent-assistant.jpg" />
        </div>
        <div class="flex">
          <h3>{{ 'IntelligentAssist' | translate }}</h3>
          <p [innerHTML]="'IntelligentAssistText' | translate"></p>
        </div>
      </mat-card>

      <mat-card appearance="outlined" class="tablet-width-50-margin-20 centered-text">
        <div class="flex">
          <img alt="WuHSterilizers" src="/assets/img/wh-sterilisatoren.jpg" />
        </div>
        <div class="flex">
          <h3>{{ 'WuHSterilizers' | translate }}</h3>
          <p [innerHTML]="'WuHSteriText' | translate"></p>
        </div>
      </mat-card>

      <mat-card appearance="outlined" class="tablet-width-50-margin-20 centered-text">
        <div class="flex">
          <img alt="ImplantmedPlus" src="/assets/img/implantmed-plus.jpg" />
        </div>
        <div class="flex">
          <h3>{{ 'ImplantmedPlus' | translate }}</h3>
          <p [innerHTML]="'ImplantmedPlusText' | translate"></p>
        </div>
      </mat-card>
    </div>
  </div>
</div>
