<app-sidenav active="scope-of-service">
  <div class="centered-text">
    <h1>{{ 'ScopeOfServices' | translate }}</h1>
  </div>
  <div class="platform-section" id="first">
    <app-platform-section></app-platform-section>
  </div>
  <div class="device-management-section">
    <app-device-management-section></app-device-management-section>
  </div>
  <div class="sterilization-section">
    <app-sterilization-section></app-sterilization-section>
  </div>
  <div class="intelligent-assist-section">
    <app-intelligent-assist-section></app-intelligent-assist-section>
  </div>
  <div class="map-area-section">
    <app-map-area></app-map-area>
  </div>

  <div class="customer-management-section">
    <app-customer-management-section></app-customer-management-section>
  </div>
  <div class="supported-devices-section">
    <app-supported-devices-section></app-supported-devices-section>
  </div>
  <div class="system-requirements-section">
    <app-system-requirements-section></app-system-requirements-section>
  </div>
</app-sidenav>
