export const $T = {
  'AboutIodent': 'AboutIodent',
  'AllDevices': 'AllDevices',
  'AvailableLanguages': 'AvailableLanguages',
  'Benefits': 'Benefits',
  'BenefitsHeadline': 'BenefitsHeadline',
  'BenefitsText': 'BenefitsText',
  'ConnectDevices': 'ConnectDevices',
  'ConnectionState': 'ConnectionState',
  'ConsumablesInformation': 'ConsumablesInformation',
  'Contact': 'Contact',
  'ContactYourDealer': 'ContactYourDealer',
  'ContactYourDealerLink': 'ContactYourDealerLink',
  'CustomAlarms': 'CustomAlarms',
  'CustomAlarmsOptionConfigurableNotification': 'CustomAlarmsOptionConfigurableNotification',
  'CustomAlarmsOptionConfigurableNotificationConsumables': 'CustomAlarmsOptionConfigurableNotificationConsumables',
  'CustomAlarmsOptionConfigurableNotificationError': 'CustomAlarmsOptionConfigurableNotificationError',
  'CustomAlarmsOptionConfigurableNotificationOffline': 'CustomAlarmsOptionConfigurableNotificationOffline',
  'CustomAlarmsOptionConfigurableNotificationService': 'CustomAlarmsOptionConfigurableNotificationService',
  'CustomAlarmsOptionConfigurableNotificationTicket': 'CustomAlarmsOptionConfigurableNotificationTicket',
  'CustomAlarmsOptionCustomizedAlarms': 'CustomAlarmsOptionCustomizedAlarms',
  'CustomAlarmsOptionVacuumTest': 'CustomAlarmsOptionVacuumTest',
  'CustomerInformation': 'CustomerInformation',
  'CustomerManagement': 'CustomerManagement',
  'CustomerManagementDescription': 'CustomerManagementDescription',
  'CustomerManagementHeadline': 'CustomerManagementHeadline',
  'CustomerManagementOptionCreateDeleteEditCustomer': 'CustomerManagementOptionCreateDeleteEditCustomer',
  'CustomerManagementOptionCustomerInactive': 'CustomerManagementOptionCustomerInactive',
  'CustomerManagementOptionInviteCustomer': 'CustomerManagementOptionInviteCustomer',
  'DeviceCategory1': 'DeviceCategory1',
  'DeviceCategory2': 'DeviceCategory2',
  'DeviceCategory3': 'DeviceCategory3',
  'DeviceCategory4': 'DeviceCategory4',
  'DeviceCategory5': 'DeviceCategory5',
  'DeviceCategory6': 'DeviceCategory6',
  'DeviceManagement': 'DeviceManagement',
  'DeviceManagementDescription': 'DeviceManagementDescription',
  'DeviceNameConfiguration': 'DeviceNameConfiguration',
  'Devices': 'Devices',
  'DevicesHeadline': 'DevicesHeadline',
  'DevicesTextDesktop': 'DevicesTextDesktop',
  'DevicesTextMobile': 'DevicesTextMobile',
  'DocumentStorage': 'DocumentStorage',
  'EfficiencyHeadline': 'EfficiencyHeadline',
  'EfficiencyText': 'EfficiencyText',
  'Endtest': 'Endtest',
  'EndtestChecklistHeadline': 'EndtestChecklistHeadline',
  'EndtestChecklistText': 'EndtestChecklistText',
  'English': 'English',
  'ErrorDashboard': 'ErrorDashboard',
  'ErrorDashboardOptionDocumentUpload': 'ErrorDashboardOptionDocumentUpload',
  'ErrorDashboardOptionDownloadRemotely': 'ErrorDashboardOptionDownloadRemotely',
  'ErrorDashboardOptionFiltering': 'ErrorDashboardOptionFiltering',
  'ErrorDashboardOptionLiveUpdate': 'ErrorDashboardOptionLiveUpdate',
  'ErrorDashboardOptionSolutionSuggestion': 'ErrorDashboardOptionSolutionSuggestion',
  'ErrorReadoutDescription': 'ErrorReadoutDescription',
  'ErrorReadoutHeadline': 'ErrorReadoutHeadline',
  'FeedbackHeadline': 'FeedbackHeadline',
  'FeedbackText': 'FeedbackText',
  'FilterAndSearch': 'FilterAndSearch',
  'FutureDentistry': 'FutureDentistry',
  'FutureDentistryText': 'FutureDentistryText',
  'GeneralServiceDocuments': 'GeneralServiceDocuments',
  'German': 'German',
  'HeaderHeadline': 'HeaderHeadline',
  'HeaderText': 'HeaderText',
  'HistoryCardHeadline1': 'HistoryCardHeadline1',
  'HistoryCardHeadline2': 'HistoryCardHeadline2',
  'HistoryCardItem11': 'HistoryCardItem11',
  'HistoryCardItem12': 'HistoryCardItem12',
  'HistoryCardItem13': 'HistoryCardItem13',
  'HistoryCardItem14': 'HistoryCardItem14',
  'HistoryCardItem15': 'HistoryCardItem15',
  'HistoryCardItem21': 'HistoryCardItem21',
  'HistoryCardItem22': 'HistoryCardItem22',
  'HistoryCardItem23': 'HistoryCardItem23',
  'HistoryCardItem24': 'HistoryCardItem24',
  'HistoryCardItem25': 'HistoryCardItem25',
  'HistoryCardItem26': 'HistoryCardItem26',
  'HistoryCardText1': 'HistoryCardText1',
  'HistoryCardText2': 'HistoryCardText2',
  'HistoryHeadline': 'HistoryHeadline',
  'Home': 'Home',
  'HowTo': 'HowTo',
  'HowTo1': 'HowTo1',
  'HowTo2': 'HowTo2',
  'HowTo3': 'HowTo3',
  'HowTo4': 'HowTo4',
  'HowTo5': 'HowTo5',
  'HowTo6': 'HowTo6',
  'HowToText': 'HowToText',
  'Implantmed': 'Implantmed',
  'ImplantmedPlus': 'ImplantmedPlus',
  'ImplantmedPlusText': 'ImplantmedPlusText',
  'InstallationGuide': 'InstallationGuide',
  'IntelligentAssist': 'IntelligentAssist',
  'IntelligentAssistText': 'IntelligentAssistText',
  'Italian': 'Italian',
  'LandingPage': 'LandingPage',
  'LegalInfoPrivacyStatement': 'LegalInfoPrivacyStatement',
  'LegalInformation': 'LegalInformation',
  'Licensor': 'Licensor',
  'LogIn': 'LogIn',
  'MapView': 'MapView',
  'MapViewText': 'MapViewText',
  'Of': 'Of',
  'PDFHowToDownload': 'PDFHowToDownload',
  'PeaceHeadline': 'PeaceHeadline',
  'PeaceText': 'PeaceText',
  'PlatformDescription1': 'PlatformDescription1',
  'PlatformDescription2': 'PlatformDescription2',
  'PrivacyPolicy1': 'PrivacyPolicy1',
  'PrivacyPolicy10': 'PrivacyPolicy10',
  'PrivacyPolicy101': 'PrivacyPolicy101',
  'PrivacyPolicy102': 'PrivacyPolicy102',
  'PrivacyPolicy11': 'PrivacyPolicy11',
  'PrivacyPolicy110': 'PrivacyPolicy110',
  'PrivacyPolicy111': 'PrivacyPolicy111',
  'PrivacyPolicy112': 'PrivacyPolicy112',
  'PrivacyPolicy113': 'PrivacyPolicy113',
  'PrivacyPolicy11a': 'PrivacyPolicy11a',
  'PrivacyPolicy11b': 'PrivacyPolicy11b',
  'PrivacyPolicy11c': 'PrivacyPolicy11c',
  'PrivacyPolicy11d': 'PrivacyPolicy11d',
  'PrivacyPolicy11e': 'PrivacyPolicy11e',
  'PrivacyPolicy11f': 'PrivacyPolicy11f',
  'PrivacyPolicy11g': 'PrivacyPolicy11g',
  'PrivacyPolicy12': 'PrivacyPolicy12',
  'PrivacyPolicy13': 'PrivacyPolicy13',
  'PrivacyPolicy14': 'PrivacyPolicy14',
  'PrivacyPolicy15': 'PrivacyPolicy15',
  'PrivacyPolicy2': 'PrivacyPolicy2',
  'PrivacyPolicy21': 'PrivacyPolicy21',
  'PrivacyPolicy3': 'PrivacyPolicy3',
  'PrivacyPolicy31': 'PrivacyPolicy31',
  'PrivacyPolicy31a': 'PrivacyPolicy31a',
  'PrivacyPolicy31b': 'PrivacyPolicy31b',
  'PrivacyPolicy4': 'PrivacyPolicy4',
  'PrivacyPolicy41': 'PrivacyPolicy41',
  'PrivacyPolicy41a': 'PrivacyPolicy41a',
  'PrivacyPolicy41b': 'PrivacyPolicy41b',
  'PrivacyPolicy5': 'PrivacyPolicy5',
  'PrivacyPolicy51': 'PrivacyPolicy51',
  'PrivacyPolicy51a': 'PrivacyPolicy51a',
  'PrivacyPolicy51b': 'PrivacyPolicy51b',
  'PrivacyPolicy52': 'PrivacyPolicy52',
  'PrivacyPolicy53': 'PrivacyPolicy53',
  'PrivacyPolicy53a': 'PrivacyPolicy53a',
  'PrivacyPolicy53b': 'PrivacyPolicy53b',
  'PrivacyPolicy53c': 'PrivacyPolicy53c',
  'PrivacyPolicy54': 'PrivacyPolicy54',
  'PrivacyPolicy6': 'PrivacyPolicy6',
  'PrivacyPolicy61': 'PrivacyPolicy61',
  'PrivacyPolicy62': 'PrivacyPolicy62',
  'PrivacyPolicy7': 'PrivacyPolicy7',
  'PrivacyPolicy71': 'PrivacyPolicy71',
  'PrivacyPolicy72': 'PrivacyPolicy72',
  'PrivacyPolicy73': 'PrivacyPolicy73',
  'PrivacyPolicy8': 'PrivacyPolicy8',
  'PrivacyPolicy81': 'PrivacyPolicy81',
  'PrivacyPolicy81a': 'PrivacyPolicy81a',
  'PrivacyPolicy81b': 'PrivacyPolicy81b',
  'PrivacyPolicy81c': 'PrivacyPolicy81c',
  'PrivacyPolicy81d': 'PrivacyPolicy81d',
  'PrivacyPolicy81e': 'PrivacyPolicy81e',
  'PrivacyPolicy82': 'PrivacyPolicy82',
  'PrivacyPolicy83': 'PrivacyPolicy83',
  'PrivacyPolicy9': 'PrivacyPolicy9',
  'PrivacyPolicy91': 'PrivacyPolicy91',
  'PrivacyPolicyHeadline': 'PrivacyPolicyHeadline',
  'PrivacyPolicyVersion': 'PrivacyPolicyVersion',
  'Register': 'Register',
  'RegisterForFree': 'RegisterForFree',
  'RegisterForFreeBenefits': 'RegisterForFreeBenefits',
  'RemoteUpdate': 'RemoteUpdate',
  'RemoteUpdateOptionChangelog': 'RemoteUpdateOptionChangelog',
  'RemoteUpdateOptionEmailNotification': 'RemoteUpdateOptionEmailNotification',
  'RemoteUpdateOptionFeedback': 'RemoteUpdateOptionFeedback',
  'RemoteUpdateOptionTriggerRemote': 'RemoteUpdateOptionTriggerRemote',
  'RepairDrawing': 'RepairDrawing',
  'Report': 'Report',
  'Reprocessing': 'Reprocessing',
  'ReprocessingDescription': 'ReprocessingDescription',
  'ReprocessingOptionBooklet': 'ReprocessingOptionBooklet',
  'ReprocessingOptionCloudPlatform': 'ReprocessingOptionCloudPlatform',
  'ReprocessingOptionDate': 'ReprocessingOptionDate',
  'ReprocessingOptionDeviceName': 'ReprocessingOptionDeviceName',
  'ReprocessingOptionDownload': 'ReprocessingOptionDownload',
  'ReprocessingOptionEmailConfig': 'ReprocessingOptionEmailConfig',
  'ReprocessingOptionImmutable': 'ReprocessingOptionImmutable',
  'ReprocessingOptionSearch': 'ReprocessingOptionSearch',
  'ReprocessingOptionSerialNumber': 'ReprocessingOptionSerialNumber',
  'ReprocessingOptionState': 'ReprocessingOptionState',
  'ReprocessingOptionUnloader': 'ReprocessingOptionUnloader',
  'ScopeOfServices': 'ScopeOfServices',
  'SecurityHeadline': 'SecurityHeadline',
  'SecurityText': 'SecurityText',
  'Services': 'Services',
  'ServicesHeadline': 'ServicesHeadline',
  'ServicesText': 'ServicesText',
  'ServiceToolOverviewDescription1': 'ServiceToolOverviewDescription1',
  'ServiceToolOverviewDescription2': 'ServiceToolOverviewDescription2',
  'ServiceToolOverviewDescription3': 'ServiceToolOverviewDescription3',
  'ServiceToolOverviewHeadline': 'ServiceToolOverviewHeadline',
  'ServiceToolTermsOfUse10': 'ServiceToolTermsOfUse10',
  'ServiceToolTermsOfUse100': 'ServiceToolTermsOfUse100',
  'ServiceToolTermsOfUse101': 'ServiceToolTermsOfUse101',
  'ServiceToolTermsOfUse11': 'ServiceToolTermsOfUse11',
  'ServiceToolTermsOfUse110': 'ServiceToolTermsOfUse110',
  'ServiceToolTermsOfUse111': 'ServiceToolTermsOfUse111',
  'ServiceToolTermsOfUse112': 'ServiceToolTermsOfUse112',
  'ServiceToolTermsOfUse113': 'ServiceToolTermsOfUse113',
  'ServiceToolTermsOfUse114': 'ServiceToolTermsOfUse114',
  'ServiceToolTermsOfUse115': 'ServiceToolTermsOfUse115',
  'ServiceToolTermsOfUse116': 'ServiceToolTermsOfUse116',
  'ServiceToolTermsOfUse117': 'ServiceToolTermsOfUse117',
  'ServiceToolTermsOfUse118': 'ServiceToolTermsOfUse118',
  'ServiceToolTermsOfUse12': 'ServiceToolTermsOfUse12',
  'ServiceToolTermsOfUse13': 'ServiceToolTermsOfUse13',
  'ServiceToolTermsOfUse14': 'ServiceToolTermsOfUse14',
  'ServiceToolTermsOfUse20': 'ServiceToolTermsOfUse20',
  'ServiceToolTermsOfUse21': 'ServiceToolTermsOfUse21',
  'ServiceToolTermsOfUse22': 'ServiceToolTermsOfUse22',
  'ServiceToolTermsOfUse23': 'ServiceToolTermsOfUse23',
  'ServiceToolTermsOfUse24': 'ServiceToolTermsOfUse24',
  'ServiceToolTermsOfUse25': 'ServiceToolTermsOfUse25',
  'ServiceToolTermsOfUse30': 'ServiceToolTermsOfUse30',
  'ServiceToolTermsOfUse31': 'ServiceToolTermsOfUse31',
  'ServiceToolTermsOfUse32': 'ServiceToolTermsOfUse32',
  'ServiceToolTermsOfUse33': 'ServiceToolTermsOfUse33',
  'ServiceToolTermsOfUse34': 'ServiceToolTermsOfUse34',
  'ServiceToolTermsOfUse35': 'ServiceToolTermsOfUse35',
  'ServiceToolTermsOfUse36': 'ServiceToolTermsOfUse36',
  'ServiceToolTermsOfUse40': 'ServiceToolTermsOfUse40',
  'ServiceToolTermsOfUse41': 'ServiceToolTermsOfUse41',
  'ServiceToolTermsOfUse42': 'ServiceToolTermsOfUse42',
  'ServiceToolTermsOfUse43': 'ServiceToolTermsOfUse43',
  'ServiceToolTermsOfUse44': 'ServiceToolTermsOfUse44',
  'ServiceToolTermsOfUse50': 'ServiceToolTermsOfUse50',
  'ServiceToolTermsOfUse51': 'ServiceToolTermsOfUse51',
  'ServiceToolTermsOfUse60': 'ServiceToolTermsOfUse60',
  'ServiceToolTermsOfUse61': 'ServiceToolTermsOfUse61',
  'ServiceToolTermsOfUse62': 'ServiceToolTermsOfUse62',
  'ServiceToolTermsOfUse63': 'ServiceToolTermsOfUse63',
  'ServiceToolTermsOfUse64': 'ServiceToolTermsOfUse64',
  'ServiceToolTermsOfUse70': 'ServiceToolTermsOfUse70',
  'ServiceToolTermsOfUse71': 'ServiceToolTermsOfUse71',
  'ServiceToolTermsOfUse72': 'ServiceToolTermsOfUse72',
  'ServiceToolTermsOfUse73': 'ServiceToolTermsOfUse73',
  'ServiceToolTermsOfUse74': 'ServiceToolTermsOfUse74',
  'ServiceToolTermsOfUse75': 'ServiceToolTermsOfUse75',
  'ServiceToolTermsOfUse76': 'ServiceToolTermsOfUse76',
  'ServiceToolTermsOfUse77': 'ServiceToolTermsOfUse77',
  'ServiceToolTermsOfUse80': 'ServiceToolTermsOfUse80',
  'ServiceToolTermsOfUse81': 'ServiceToolTermsOfUse81',
  'ServiceToolTermsOfUse82': 'ServiceToolTermsOfUse82',
  'ServiceToolTermsOfUse83': 'ServiceToolTermsOfUse83',
  'ServiceToolTermsOfUse84': 'ServiceToolTermsOfUse84',
  'ServiceToolTermsOfUse90': 'ServiceToolTermsOfUse90',
  'ServiceToolTermsOfUse91': 'ServiceToolTermsOfUse91',
  'ServiceToolTermsOfUse92': 'ServiceToolTermsOfUse92',
  'ServiceToolUsage': 'ServiceToolUsage',
  'SingleFunctionTestDescription': 'SingleFunctionTestDescription',
  'SingleFunctionTestHeadline': 'SingleFunctionTestHeadline',
  'SparePartDrawing': 'SparePartDrawing',
  'Sterilizer': 'Sterilizer',
  'SuborganisationManagement': 'SuborganisationManagement',
  'SuborganisationManagementOptionCreateSuborganisations': 'SuborganisationManagementOptionCreateSuborganisations',
  'SupportedDevices': 'SupportedDevices',
  'SystemRequirements': 'SystemRequirements',
  'SystemRequirementsOption1': 'SystemRequirementsOption1',
  'SystemRequirementsOption2': 'SystemRequirementsOption2',
  'SystemRequirementsOption3': 'SystemRequirementsOption3',
  'SystemRequirementsOption4': 'SystemRequirementsOption4',
  'SystemRequirementsOption5': 'SystemRequirementsOption5',
  'SystemRequirementsOption6': 'SystemRequirementsOption6',
  'SystemRequirementsOption7': 'SystemRequirementsOption7',
  'TechInfo': 'TechInfo',
  'Terms': 'Terms',
  'TermsOfUse': 'TermsOfUse',
  'TermsOfUse1': 'TermsOfUse1',
  'TermsOfUse10': 'TermsOfUse10',
  'TermsOfUse101': 'TermsOfUse101',
  'TermsOfUse102': 'TermsOfUse102',
  'TermsOfUse103': 'TermsOfUse103',
  'TermsOfUse104': 'TermsOfUse104',
  'TermsOfUse105': 'TermsOfUse105',
  'TermsOfUse106': 'TermsOfUse106',
  'TermsOfUse11': 'TermsOfUse11',
  'TermsOfUse111': 'TermsOfUse111',
  'TermsOfUse1111': 'TermsOfUse1111',
  'TermsOfUse1112': 'TermsOfUse1112',
  'TermsOfUse12': 'TermsOfUse12',
  'TermsOfUse121': 'TermsOfUse121',
  'TermsOfUse1211': 'TermsOfUse1211',
  'TermsOfUse1212': 'TermsOfUse1212',
  'TermsOfUse1213': 'TermsOfUse1213',
  'TermsOfUse13': 'TermsOfUse13',
  'TermsOfUse14': 'TermsOfUse14',
  'TermsOfUse15': 'TermsOfUse15',
  'TermsOfUse2': 'TermsOfUse2',
  'TermsOfUse21': 'TermsOfUse21',
  'TermsOfUse22': 'TermsOfUse22',
  'TermsOfUse23': 'TermsOfUse23',
  'TermsOfUse3': 'TermsOfUse3',
  'TermsOfUse31': 'TermsOfUse31',
  'TermsOfUse32': 'TermsOfUse32',
  'TermsOfUse33': 'TermsOfUse33',
  'TermsOfUse34': 'TermsOfUse34',
  'TermsOfUse35': 'TermsOfUse35',
  'TermsOfUse36': 'TermsOfUse36',
  'TermsOfUse36a': 'TermsOfUse36a',
  'TermsOfUse36b': 'TermsOfUse36b',
  'TermsOfUse4': 'TermsOfUse4',
  'TermsOfUse41': 'TermsOfUse41',
  'TermsOfUse41a': 'TermsOfUse41a',
  'TermsOfUse41b': 'TermsOfUse41b',
  'TermsOfUse41c': 'TermsOfUse41c',
  'TermsOfUse41d': 'TermsOfUse41d',
  'TermsOfUse41e': 'TermsOfUse41e',
  'TermsOfUse41f': 'TermsOfUse41f',
  'TermsOfUse41g': 'TermsOfUse41g',
  'TermsOfUse41h': 'TermsOfUse41h',
  'TermsOfUse41i': 'TermsOfUse41i',
  'TermsOfUse41j': 'TermsOfUse41j',
  'TermsOfUse42': 'TermsOfUse42',
  'TermsOfUse43': 'TermsOfUse43',
  'TermsOfUse44': 'TermsOfUse44',
  'TermsOfUse45': 'TermsOfUse45',
  'TermsOfUse5': 'TermsOfUse5',
  'TermsOfUse51': 'TermsOfUse51',
  'TermsOfUse52': 'TermsOfUse52',
  'TermsOfUse53': 'TermsOfUse53',
  'TermsOfUse54': 'TermsOfUse54',
  'TermsOfUse55': 'TermsOfUse55',
  'TermsOfUse56': 'TermsOfUse56',
  'TermsOfUse6': 'TermsOfUse6',
  'TermsOfUse61': 'TermsOfUse61',
  'TermsOfUse62': 'TermsOfUse62',
  'TermsOfUse7': 'TermsOfUse7',
  'TermsOfUse71': 'TermsOfUse71',
  'TermsOfUse72': 'TermsOfUse72',
  'TermsOfUse73': 'TermsOfUse73',
  'TermsOfUse74': 'TermsOfUse74',
  'TermsOfUse74a': 'TermsOfUse74a',
  'TermsOfUse74b': 'TermsOfUse74b',
  'TermsOfUse74c': 'TermsOfUse74c',
  'TermsOfUse74d': 'TermsOfUse74d',
  'TermsOfUse75': 'TermsOfUse75',
  'TermsOfUse8': 'TermsOfUse8',
  'TermsOfUse81': 'TermsOfUse81',
  'TermsOfUse82': 'TermsOfUse82',
  'TermsOfUse83': 'TermsOfUse83',
  'TermsOfUse84': 'TermsOfUse84',
  'TermsOfUse85': 'TermsOfUse85',
  'TermsOfUse9': 'TermsOfUse9',
  'TermsOfUse91': 'TermsOfUse91',
  'TermsOfUseHeadline': 'TermsOfUseHeadline',
  'ToWH': 'ToWH',
  'Up': 'Up',
  'UserManagement': 'UserManagement',
  'UserManagementOptionCreateDeleteEditUsers': 'UserManagementOptionCreateDeleteEditUsers',
  'UserManagementOptionUsersInactive': 'UserManagementOptionUsersInactive',
  'Validation': 'Validation',
  'Version': 'Version',
  'VersionToUServiceTool': 'VersionToUServiceTool',
  'WuHSterilizers': 'WuHSterilizers',
  'WuHSteriText': 'WuHSteriText',
};
