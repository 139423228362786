import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from 'ngx-flexible-layout';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule, MatIconModule, MatCardModule, FlexLayoutModule],
  selector: 'app-customer-management-section',
  templateUrl: './customer-management-section.component.html',
  styleUrls: ['./customer-management-section.component.scss'],
})
export class CustomerManagementSectionComponent {}
