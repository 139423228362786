<div class="max-width-1920-inner">
  <div>
    <div class="centered-text tablet-left-text mobile-left-text">
      <h2>{{ $T.HistoryHeadline | translate }}</h2>
    </div>
    <div class="row row-gap-20 tablet-column mobile-column">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            <h3>{{ $T.HistoryCardHeadline1 | translate }}</h3>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div>
            <p>{{ $T.HistoryCardText1 | translate }}.</p>
          </div>
          <ul>
            <li>{{ $T.HistoryCardItem11 | translate }}</li>
            <li>{{ $T.HistoryCardItem12 | translate }}</li>
            <li>{{ $T.HistoryCardItem13 | translate }}</li>
            <li>{{ $T.HistoryCardItem14 | translate }}</li>
          </ul>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            <h3>{{ $T.HistoryCardHeadline2 | translate }}</h3>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div>
            <p>{{ $T.HistoryCardText2 | translate }}.</p>
          </div>
          <ul>
            <li>{{ $T.HistoryCardItem21 | translate }}</li>
            <li>{{ $T.HistoryCardItem22 | translate }}</li>
            <li>{{ $T.HistoryCardItem23 | translate }}</li>
            <li>{{ $T.HistoryCardItem24 | translate }}</li>
            <li>{{ $T.HistoryCardItem25 | translate }}</li>
            <li>{{ $T.HistoryCardItem26 | translate }}</li>
          </ul>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
