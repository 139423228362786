<div class="max-width-1920-inner">
  <div class="row mobile-column tablet-column">
    <div class="tablet-column mobile-column flex flex-07">
      <h2>{{ 'ErrorReadoutHeadline' | translate }}</h2>
      <p>{{ 'ErrorReadoutDescription' | translate }}</p>
    </div>
    <div class="tablet-column flex position-relative">
      <div class="image-container image-container-left">
        <img alt="Errorcodes" src="/assets/img/Errorcodes.png" />
      </div>
    </div>
  </div>
</div>
