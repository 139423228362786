<div class="max-width-1920-inner">
  <div class="row mobile-column tablet-column tablet-column-max-width-640">
    <div class="tablet-column-center-text mobile-column flex flex-07">
      <a class="logo" href="https://www.wh.com">
        <img class="wh" alt="wh" src="/assets/icons/wh.svg" />
      </a>
      <h1 [innerHTML]="'HeaderHeadline' | translate" class="white"></h1>
      <p [innerHTML]="'HeaderText' | translate" class="white"></p>
      <button (click)="onRegister()" class="tall white-text" color="accent" mat-flat-button>
        <mat-icon svgIcon="arrow-right-white"></mat-icon>
        {{ 'RegisterForFree' | translate }}
      </button>
    </div>
    <div class="tablet-column-center-text flex position-relative">
      <div class="image-container">
        <div class="bubble backslash-n-brake">{{ 'RegisterForFreeBenefits' | translate }} <mat-icon svgIcon="arrow-right"></mat-icon></div>
        <img alt="Laptop" src="/assets/img/Laptop.png" />
      </div>
    </div>
  </div>
</div>
