import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { SidenavComponent } from '../../sidenav/sidenav.component';
import { ToolbarComponent } from '../../toolbar/toolbar.component';
import { CustomerManagementSectionComponent } from './customer-management-section/customer-management-section.component';
import { DeviceManagementSectionComponent } from './device-management-section/device-management-section.component';
import { IntelligentAssistSectionComponent } from './intelligent-assist-section/intelligent-assist-section.component';
import { MapAreaComponent } from './map-area/map-area.component';
import { PlatformSectionComponent } from './platform-section/platform-section.component';
import { SterilizationSectionComponent } from './sterilization-section/sterilization-section.component';
import { SupportedDevicesSectionComponent } from './supported-devices-section/supported-devices-section.component';
import { SystemRequirementsSectionComponent } from './system-requirements-section/system-requirements-section.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ToolbarComponent,
    RouterModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    PlatformSectionComponent,
    DeviceManagementSectionComponent,
    SterilizationSectionComponent,
    IntelligentAssistSectionComponent,
    CustomerManagementSectionComponent,
    SupportedDevicesSectionComponent,
    SystemRequirementsSectionComponent,
    MapAreaComponent,
    FlexLayoutModule,
    SidenavComponent,
  ],
  selector: 'app-scope-of-service',
  templateUrl: './scope-of-service.page.html',
  styleUrls: ['./scope-of-service.page.scss'],
})
export class ScopeOfServicePage {}
