import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { $T } from 'src/assets/i18n/Translation-keys';

@Component({
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslateModule, MatButtonModule, FlexLayoutModule],
  selector: 'app-information-section',
  templateUrl: './information-section.component.html',
  styleUrls: ['./information-section.component.scss'],
})
export class InformationSectionComponent {
  $T = $T;
}
