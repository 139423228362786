<app-toolbar [sidenav]="drawer" active="{{ active }}"> </app-toolbar>

<mat-sidenav-container class="sections-container" hasBackdrop="false">
  <mat-sidenav class="side-nav-menu" [@.disabled]="true" #drawer mode="over" position="end">
    <div class="column flex">
      <div class="column actions">
        <button (click)="onLogin()" class="login" mat-stroked-button>{{ 'LogIn' | translate }}</button>
        <button (click)="onRegister()" class="register white-text" color="accent" mat-flat-button>{{ 'Register' | translate }}</button>
      </div>
      <mat-nav-list>
        <a
          [ngClass]="{ active: active === 'home' }"
          [routerLink]="['/']"
          (click)="drawer.toggle()"
          class="menu-item"
          id="home-menu-item"
          mat-list-item
          >{{ 'Home' | translate }}</a
        >
        <a
          [ngClass]="{ active: active === 'connect-devices' }"
          [routerLink]="['/connect-devices']"
          (click)="drawer.toggle()"
          class="menu-item"
          mat-list-item
          >{{ 'ConnectDevices' | translate }}</a
        >
      </mat-nav-list>
      <div class="language-container">
        <div class="auto-width gray">
          <mat-form-field class="language-select" subscriptSizing="dynamic">
            <mat-select
              (selectionChange)="onLanguageChange()"
              [(value)]="selectedLanguage"
              disableOptionCentering
              panelClass="language-select-panel">
              <mat-option *ngFor="let language of languages" [value]="language.language">
                {{ language.languageText }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="{{ containerClass }}">
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
