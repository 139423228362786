<div class="max-width-1920-inner">
  <div>
    <div class="centered-text column">
      <h2 [innerHTML]="'DevicesHeadline' | translate" class="backslash-n-brake"></h2>
      <h4 [innerHTML]="'DevicesTextDesktop' | translate" class="block"></h4>
    </div>
    <div class="row centered-text">
      <img alt="Devices" src="/assets/img/Devices.jpg" />
    </div>
  </div>
</div>
