<div class="max-width-1920-inner">
  <div class="column centered-text">
    <h2>{{ 'HowTo' | translate }}</h2>
    <h4 [innerHTML]="'HowToText' | translate" class="bold"></h4>
  </div>

  <div class="row row-wrap row-center">
    <app-how-to-section-step iconPath="/assets/icons/device-lisa2015-3d.svg" step="1." text="HowTo1"></app-how-to-section-step>
    <app-how-to-section-step iconPath="/assets/img/qrcode.png" step="2." text="HowTo2"></app-how-to-section-step>
    <app-how-to-section-step iconPath="/assets/img/Laptop.png" step="3." text="HowTo3"></app-how-to-section-step>
    <app-how-to-section-step iconPath="/assets/icons/DM_3D.svg" step="4." text="HowTo4"></app-how-to-section-step>
    <app-how-to-section-step iconPath="/assets/icons/installationguide_icons_5.svg" step="5." text="HowTo5"></app-how-to-section-step>
    <app-how-to-section-step iconPath="/assets/icons/installationguide_icons_6.svg" step="6." text="HowTo6"></app-how-to-section-step>
  </div>

  <div class="mobile-column centered-text">
    <button (click)="onDownloadFlyer()" mat-stroked-button>
      <mat-icon svgIcon="download"></mat-icon>
      {{ 'PDFHowToDownload' | translate }}
    </button>
  </div>
</div>
