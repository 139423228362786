import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from 'ngx-flexible-layout';

@Component({
  selector: 'app-overview-section',
  standalone: true,
  templateUrl: './overview-section.component.html',
  styleUrls: ['./overview-section.component.scss'],
  imports: [CommonModule, TranslateModule, RouterModule, MatIconModule, FlexLayoutModule],
})
export class OverviewSectionComponent {}
