<app-sidenav active="privacy-policy" containerClass="content-container">
  <div class="max-width-1920-inner" id="first">
    <div [innerHtml]="'LegalInformation' | translate"></div>

    <div class="column centered-text">
      <h2 [innerHTML]="'PrivacyPolicyHeadline' | translate"></h2>
      <p>{{'PrivacyPolicyVersion' | translate}}</p>
    </div>

    <h3>{{'PrivacyPolicy1' | translate}}</h3>
    <p>
      {{'PrivacyPolicy11' | translate | split:0}}
      <b>{{'PrivacyPolicy11' | translate | split:1}}</b>
      {{'PrivacyPolicy11' | translate | split:2}}
      <b>{{'PrivacyPolicy11' | translate | split:3}}</b>
      <span [innerHTML]="'PrivacyPolicy11' | translate | split:4"></span>
      <b>{{'PrivacyPolicy11' | translate | split:5}}</b>
      {{'PrivacyPolicy11' | translate | split:6}}
      <b>{{'PrivacyPolicy11' | translate | split:7}}</b>
      {{'PrivacyPolicy11' | translate | split:8}}
    </p>
    <p>
      {{'PrivacyPolicy12' | translate | split:0}}
      <b>{{'PrivacyPolicy12' | translate | split:1}}</b>
      {{'PrivacyPolicy12' | translate | split:2}}
      <b>{{'PrivacyPolicy12' | translate | split:3}}</b>
      {{'PrivacyPolicy12' | translate | split:4}}
      <b>{{'PrivacyPolicy12' | translate | split:5}}</b>
      {{'PrivacyPolicy12' | translate | split:6}}
      <b>{{'PrivacyPolicy12' | translate | split:7}}</b>
      {{'PrivacyPolicy12' | translate | split:8}}
      <b>{{'PrivacyPolicy12' | translate | split:9}}</b>
      {{'PrivacyPolicy12' | translate | split:10}}
    </p>
    <p>{{'PrivacyPolicy13' | translate}}</p>
    <p>{{'PrivacyPolicy14' | translate}}</p>
    <p>{{'PrivacyPolicy15' | translate}}</p>

    <div>
      <h3>{{'PrivacyPolicy2' | translate}}</h3>
      <p>{{'PrivacyPolicy21' | translate}}</p>
    </div>
    <div>
      <h3>{{'PrivacyPolicy3' | translate}}</h3>
      <p>{{'PrivacyPolicy31' | translate}}</p>
      <ul class="roman-list">
        <li>{{'PrivacyPolicy31a' | translate}}</li>
        <li>{{'PrivacyPolicy31b' | translate}}</li>
      </ul>
    </div>
    <div>
      <h3>{{'PrivacyPolicy4' | translate}}</h3>
      <p>{{'PrivacyPolicy41' | translate}}</p>
      <ul class="roman-list">
        <li>{{'PrivacyPolicy41a' | translate}}</li>
        <li>{{'PrivacyPolicy41b' | translate}}</li>
      </ul>
    </div>
    <div>
      <h3>{{'PrivacyPolicy5' | translate}}</h3>
      <p>{{'PrivacyPolicy51' | translate}}</p>
      <ul>
        <li>{{'PrivacyPolicy51a' | translate}}</li>
        <li>{{'PrivacyPolicy51b' | translate}}</li>
      </ul>
      <p>{{'PrivacyPolicy52' | translate}}</p>
      <p>{{'PrivacyPolicy53' | translate}}</p>
      <ul>
        <li>{{'PrivacyPolicy53a' | translate}}</li>
        <li>{{'PrivacyPolicy53b' | translate}}</li>
        <li>{{'PrivacyPolicy53c' | translate}}</li>
      </ul>
      <p>{{'PrivacyPolicy54' | translate}}</p>
    </div>
    <div>
      <h3>{{'PrivacyPolicy6' | translate}}</h3>
      <p>{{'PrivacyPolicy61' | translate}}</p>
      <p>{{'PrivacyPolicy62' | translate}}</p>
    </div>
    <div>
      <h3>{{'PrivacyPolicy7' | translate}}</h3>
      <p>{{'PrivacyPolicy71' | translate}}</p>
      <p>{{'PrivacyPolicy72' | translate}}</p>
      <p>{{'PrivacyPolicy73' | translate}}</p>
    </div>
    <div>
      <h3>{{'PrivacyPolicy8' | translate}}</h3>
      <p>{{'PrivacyPolicy81' | translate}}</p>
      <ul class="roman-list">
        <li>{{'PrivacyPolicy81a' | translate}}</li>
        <li>{{'PrivacyPolicy81b' | translate}}</li>
        <li>{{'PrivacyPolicy81c' | translate}}</li>
        <li>{{'PrivacyPolicy81d' | translate}}</li>
        <li>{{'PrivacyPolicy81e' | translate}}</li>
      </ul>
      <p>{{'PrivacyPolicy82' | translate}}</p>
      <p>{{'PrivacyPolicy83' | translate}}</p>
    </div>
    <div>
      <h3>{{'PrivacyPolicy9' | translate}}</h3>
      <p>{{'PrivacyPolicy91' | translate}}</p>
    </div>
    <div>
      <h3>{{'PrivacyPolicy10' | translate}}</h3>
      <p>{{'PrivacyPolicy101' | translate}}</p>
      <p>{{'PrivacyPolicy102' | translate}}</p>
    </div>

    <div>
      <h3>{{'PrivacyPolicy110' | translate}}</h3>
      <p [innerHTML]="'PrivacyPolicy111' | translate"></p>
      <ul>
        <li>{{'PrivacyPolicy11a' | translate}}</li>
        <li>{{'PrivacyPolicy11b' | translate}}</li>
        <li>{{'PrivacyPolicy11c' | translate}}</li>
        <li>{{'PrivacyPolicy11d' | translate}}</li>
        <li>{{'PrivacyPolicy11e' | translate}}</li>
        <li>{{'PrivacyPolicy11f' | translate}}</li>
        <li>{{'PrivacyPolicy11g' | translate}}</li>
      </ul>
      <p>{{'PrivacyPolicy112' | translate}}</p>
      <p [innerHTML]="'PrivacyPolicy113' | translate"></p>
    </div>

    <div id="dr-privacynotice-div"></div>

    <div class="centered-text contact">
      <p>
        <b>W&amp;H Dentalwerk B&uuml;rmoos GmbH</b> <br />
        Ignaz-Glasner-Straße 53 <br />
        5111 B&uuml;rmoos <br />
        Austria <br />
        dataprotection@wh.com
      </p>
    </div>
  </div>
</app-sidenav>
