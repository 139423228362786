<div class="max-width-1920-inner">
  <div>
    <div class="centered-text tablet-column tablet-column-max-width-640">
      <h2 [innerHTML]="'BenefitsHeadline' | translate"></h2>
      <h4 [innerHTML]="'BenefitsText' | translate" class="backslash-n-brake"></h4>
    </div>
    <div class="row row-gap-20 mobile-column tablet-column tablet-column-max-width-454">
      <div class="tablet-row flex">
        <div class="mat-icon-container">
          <mat-icon class="bg" svgIcon="esagono"> </mat-icon>
          <mat-icon class="icon" svgIcon="improved-efficiency"> </mat-icon>
        </div>
        <div class="tablet-column centered-text tablet-column-left-text">
          <h3>{{ 'EfficiencyHeadline' | translate }}</h3>
          <p [innerHTML]="'EfficiencyText' | translate"></p>
        </div>
      </div>
      <div class="tablet-row flex">
        <div class="mat-icon-container">
          <mat-icon class="bg" svgIcon="esagono"> </mat-icon>
          <mat-icon class="icon" svgIcon="security"></mat-icon>
        </div>
        <div class="tablet-column centered-text tablet-column-left-text">
          <h3>{{ 'SecurityHeadline' | translate }}</h3>
          <p [innerHTML]="'SecurityText' | translate"></p>
        </div>
      </div>
      <div class="tablet-row flex">
        <div class="mat-icon-container">
          <mat-icon class="bg" svgIcon="esagono"> </mat-icon>
          <mat-icon class="icon" svgIcon="peace-of-mind"></mat-icon>
        </div>
        <div class="tablet-column centered-text tablet-column-left-text">
          <h3>{{ 'PeaceHeadline' | translate }}</h3>
          <p [innerHTML]="'PeaceText' | translate"></p>
        </div>
      </div>
    </div>
  </div>
</div>
