<div class="max-width-1920-inner">
  <div class="row mobile-column tablet-column">
    <div class="tablet-column mobile-column flex flex-07">
      <h2 [innerHTML]="'HeaderHeadline' | translate"></h2>
      <p [innerHTML]="'PlatformDescription1' | translate" class="spacer"></p>
      <p [innerHTML]="'PlatformDescription2' | translate" class="spacer"></p>

      <h4>{{ 'AvailableLanguages' | translate }}</h4>

      <ul class="inline-block">
        <li>{{ 'English' | translate }}</li>
        <li>{{ 'Italian' | translate }}</li>
        <li>{{ 'German' | translate }}</li>
      </ul>
    </div>
    <div class="tablet-column flex position-relative">
      <div class="image-container">
        <img alt="Devices" src="/assets/img/Devices-old.png" />
      </div>
    </div>
  </div>
</div>
