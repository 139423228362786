<div class="max-width-1920-inner">
  <div class="row mobile-column tablet-column">
    <div class="tablet-column mobile-column flex flex-1-5">
      <h2>{{ 'ServiceToolOverviewHeadline' | translate }}</h2>
      <p>{{ 'ServiceToolOverviewDescription1' | translate }}</p>
      <p>{{ 'ServiceToolOverviewDescription2' | translate }}</p>
      <p>{{ 'ServiceToolOverviewDescription3' | translate }}</p>
      <ul>
        <li>{{ 'RepairDrawing' | translate }}</li>
        <li>{{ 'SparePartDrawing' | translate }}</li>
        <li>{{ 'TechInfo' | translate }}</li>
        <li>{{ 'GeneralServiceDocuments' | translate }}</li>
      </ul>
    </div>
    <div class="tablet-column flex position-relative">
      <div class="image-container">
        <img alt="technician" src="/assets/img/servicetool.jpg" />
      </div>
    </div>
  </div>
</div>
