<app-sidenav active="terms-of-use" containerClass="content-container">
  <div class="max-width-1920-inner" id="first">
    <div class="column centered-text">
      <h2 [innerHTML]="'TermsOfUseHeadline' | translate"></h2>
      <p>{{'Terms' | translate}}</p>
    </div>

    <div class="terms-section">
      <h3 class="section-headline">1. {{'TermsOfUse1' | translate}}</h3>
      <p>
        1.1 <span [innerHTML]="'TermsOfUse11' | translate | split: 0"></span>
        <a href="https://app.iodent.com">{{'TermsOfUse11' | translate | split: 1}}</a>
        {{'TermsOfUse11' | translate | split: 2}}
      </p>
      <p>1.2 {{'TermsOfUse12' | translate}}</p>
      <p>1.3 {{'TermsOfUse13' | translate}}</p>
      <p>1.4 {{'TermsOfUse14' | translate}}</p>
      <p>1.5 {{'TermsOfUse15' | translate}}</p>
    </div>
    <div>
      <h3>2. {{'TermsOfUse2' | translate}}</h3>
      <p>
        2.1 {{'TermsOfUse21' | translate | split: 0}}
        <a href="https://iodent.com/scope-of-service">{{'TermsOfUse21' | translate | split: 1}}</a>
        {{'TermsOfUse21' | translate | split: 2}}
      </p>
      <p>2.2 {{'TermsOfUse22' | translate}}</p>
      <p>2.3 {{'TermsOfUse23' | translate}}</p>
    </div>
    <div>
      <h3>3. {{'TermsOfUse3' | translate}}</h3>
      <p>
        3.1 {{'TermsOfUse31' | translate | split: 0}}
        <a href="https://iodent.com">{{'TermsOfUse31' | translate | split: 1}}</a>
        {{'TermsOfUse31' | translate | split: 2}}
      </p>
      <p>3.2 {{'TermsOfUse32' | translate}}</p>
      <p>3.3 {{'TermsOfUse33' | translate}}</p>
      <p>3.4 {{'TermsOfUse34' | translate}}</p>
      <p>3.5 {{'TermsOfUse35' | translate}}</p>
      <p>3.6 {{'TermsOfUse36' | translate}}</p>
      <p>{{'TermsOfUse36a' | translate}}</p>
      <p>{{'TermsOfUse36b' | translate}}</p>
    </div>
    <div>
      <h3>4. {{'TermsOfUse4' | translate}}</h3>
      <p>4.1 {{'TermsOfUse41' | translate}}</p>
      <p>{{'TermsOfUse41a' | translate}}</p>
      <p>{{'TermsOfUse41b' | translate}}</p>
      <p>{{'TermsOfUse41c' | translate}}</p>
      <p>{{'TermsOfUse41d' | translate}}</p>
      <p>{{'TermsOfUse41e' | translate}}</p>
      <p>{{'TermsOfUse41f' | translate}}</p>
      <p>{{'TermsOfUse41g' | translate}}</p>
      <p>{{'TermsOfUse41h' | translate}}</p>
      <p>{{'TermsOfUse41i' | translate}}</p>
      <p>{{'TermsOfUse41j' | translate}}</p>
      <p>4.2 {{'TermsOfUse42' | translate}}</p>
      <p>4.3 {{'TermsOfUse43' | translate}}</p>
      <p>4.4 {{'TermsOfUse44' | translate}}</p>
      <p>4.5 {{'TermsOfUse45' | translate}}</p>
    </div>
    <div>
      <h3>5. {{'TermsOfUse5' | translate}}</h3>
      <p>5.1 {{'TermsOfUse51' | translate}}</p>
      <p>5.2 {{'TermsOfUse52' | translate}}</p>
      <p>5.3 {{'TermsOfUse53' | translate}}</p>
      <p>5.4 {{'TermsOfUse54' | translate}}</p>
      <p>5.5 {{'TermsOfUse55' | translate}}</p>
      <p>5.6 {{'TermsOfUse56' | translate}}</p>
    </div>
    <div>
      <h3>6. {{'TermsOfUse6' | translate}}</h3>
      <p>
        6.1 {{'TermsOfUse61' | translate | split: 0}}
        <a href="https://iodent.com">{{'TermsOfUse61' | translate | split: 1}}</a>
        {{'TermsOfUse61' | translate | split: 2}}
      </p>
      <p>6.2 {{'TermsOfUse62' | translate}}</p>
    </div>
    <div>
      <h3>7. {{'TermsOfUse7' | translate}}</h3>
      <p>7.1 {{'TermsOfUse71' | translate}}</p>
      <p>
        7.2 {{'TermsOfUse72' | translate | split: 0}}
        <a href="https://iodent.com/scope-of-service">{{'TermsOfUse72' | translate | split: 1}}</a>
        {{'TermsOfUse72' | translate | split: 2}}
      </p>
      <p>7.3 {{'TermsOfUse73' | translate}}</p>
      <p>7.4 {{'TermsOfUse74' | translate}}</p>
      <p>{{'TermsOfUse74a' | translate}}</p>
      <p>{{'TermsOfUse74b' | translate}}</p>
      <p>{{'TermsOfUse74c' | translate}}</p>
      <p>{{'TermsOfUse74d' | translate}}</p>
      <p>7.5 {{'TermsOfUse75' | translate}}</p>
    </div>
    <div>
      <h3>8. {{'TermsOfUse8' | translate}}</h3>
      <p>8.1 {{'TermsOfUse81' | translate}}</p>
      <p>8.2 {{'TermsOfUse82' | translate}}</p>
      <p>8.3 {{'TermsOfUse83' | translate}}</p>
      <p>8.4 {{'TermsOfUse84' | translate}}</p>
      <p>8.5 {{'TermsOfUse85' | translate}}</p>
    </div>
    <div>
      <h3>9. {{'TermsOfUse9' | translate}}</h3>
      <p>
        9.1 {{'TermsOfUse91' | translate | split: 0}}
        <a href="https://iodent.com/privacy-policy">{{'TermsOfUse91' | translate | split: 1}}</a>
        {{'TermsOfUse91' | translate | split: 2}}
      </p>
    </div>
    <div>
      <h3>10. {{'TermsOfUse10' | translate}}</h3>
      <p>10.1 {{'TermsOfUse101' | translate}}</p>
      <p>10.2 {{'TermsOfUse102' | translate}}</p>
      <p>10.3 {{'TermsOfUse103' | translate}}</p>
      <p>10.4 {{'TermsOfUse104' | translate}}</p>
      <p>10.5 {{'TermsOfUse105' | translate}}</p>
      <p>10.6 {{'TermsOfUse106' | translate}}</p>
    </div>
    <div>
      <h3>11. {{'TermsOfUse111' | translate}}</h3>
      <p>11.1 {{'TermsOfUse1111' | translate}}</p>
      <p>11.2 {{'TermsOfUse1112' | translate}}</p>
    </div>
    <div>
      <h3>12. {{'TermsOfUse121' | translate}}</h3>
      <p>12.1 {{'TermsOfUse1211' | translate}}</p>
      <p>12.2 {{'TermsOfUse1212' | translate}}</p>
      <p>12.3 {{'TermsOfUse1213' | translate}}</p>
    </div>
    <div>
      <p>{{'Version' | translate}}</p>
    </div>
  </div>
</app-sidenav>
