<div class="max-width-1920-inner">
  <div class="row mobile-column tablet-column">
    <div class="tablet-column mobile-column flex flex-07">
      <h2>{{ $T.SingleFunctionTestHeadline | translate }}</h2>
      <p class="spacer">{{ $T.SingleFunctionTestDescription | translate }}</p>
    </div>
    <div class="tablet-column flex position-relative">
      <div class="image-container">
        <img alt="Singlefunctiontest" src="/assets/img/Singlefunctiontest.png" />
      </div>
    </div>
  </div>
</div>
