<div class="centered-text max-width-1920-inner">
  <button (click)="scrollTop()" id="scrollTop" mat-stroked-button>
    <mat-icon svgIcon="arrow-top"></mat-icon>
    {{ 'Up' | translate }}
  </button>

  <button *ngIf="showScrollTopOverlay" (click)="scrollTop()" id="scrollTopOverlay" mat-flat-button>
    <mat-icon svgIcon="arrow-top"></mat-icon>
  </button>
</div>
