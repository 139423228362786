import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { SplitPipeModule } from '../../pipes';
import { SidenavComponent } from '../../sidenav/sidenav.component';
import { ToolbarComponent } from '../../toolbar/toolbar.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ToolbarComponent,
    MatIconModule,
    RouterModule,
    SplitPipeModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatButtonModule,
    FlexLayoutModule,
    MatSelectModule,
    SidenavComponent,
    SplitPipeModule,
  ],
  selector: 'app-servicetool-terms-of-use',
  templateUrl: './servicetool-terms-of-use.page.html',
  styleUrls: ['./servicetool-terms-of-use.page.scss'],
})
export class ServiceToolTermsOfUsePage {}
