<div class="max-width-1920-inner">
  <div>
    <div class="centered-text tablet-left-text mobile-left-text">
      <h2>{{ $T.SystemRequirements | translate }}</h2>
    </div>
    <ul class="list" ngClass.lt-sm="mobile">
      <li class="text" ngClass.lt-md="mobile">{{ $T.SystemRequirementsOption1 | translate }}</li>
      <li class="text" ngClass.lt-md="mobile">{{ $T.SystemRequirementsOption5 | translate }}</li>
      <li class="text" ngClass.lt-md="mobile">{{ $T.SystemRequirementsOption6 | translate }}</li>
      <li class="text" ngClass.lt-md="mobile">{{ $T.SystemRequirementsOption2 | translate }}</li>
      <li class="text" ngClass.lt-md="mobile">{{ $T.SystemRequirementsOption7 | translate }}</li>
    </ul>
  </div>
</div>
