<div class="max-width-1920-inner">
  <div class="row mobile-column tablet-column">
    <div class="tablet-column mobile-column flex flex-1-5">
      <h2>{{ $T.Reprocessing | translate }}</h2>
      <p [innerHTML]="$T.ReprocessingDescription | translate" class="spacer-small reprocessing-desctiption" ngClass.lt-md="mobile"></p>
      <ul class="list" ngClass.lt-md="mobile" ngClass.lt-sm="mobile-small">
        <li class="list-option">{{ $T.ReprocessingOptionCloudPlatform | translate }}</li>
        <li class="list-option">{{ $T.ReprocessingOptionDownload | translate }}</li>
        <li class="list-option">
          {{ $T.ReprocessingOptionSearch | translate }}
          <ul>
            <li class="spacer-top-small">{{ $T.ReprocessingOptionUnloader | translate }}</li>
            <li>{{ $T.ReprocessingOptionDate | translate }}</li>
            <li>{{ $T.ReprocessingOptionState | translate }}</li>
            <li>{{ $T.ReprocessingOptionDeviceName | translate }}</li>
            <li>{{ $T.ReprocessingOptionSerialNumber | translate }}</li>
          </ul>
        </li>
        <li class="list-option">{{ $T.ReprocessingOptionImmutable | translate }}</li>
        <li class="list-option">{{ $T.ReprocessingOptionEmailConfig | translate }}</li>
        <li class="list-option">{{ $T.ReprocessingOptionBooklet | translate }}</li>
      </ul>
    </div>

    <div class="flex position-relative tablet-column-center-text mobile-center-100">
      <div class="image-container">
        <img alt="Devices" src="/assets/icons/steri.svg" />
      </div>
    </div>
  </div>
</div>
