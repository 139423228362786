import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { SidenavComponent } from '../../sidenav/sidenav.component';
import { ToolbarComponent } from '../../toolbar/toolbar.component';
import { BenefitsSectionComponent } from '../landing-page/benefits-section/benefits-section.component';
import { CallToActionSectionComponent } from '../landing-page/call-to-action-section/call-to-action-section.component';
import { DevicesSectionComponent } from '../landing-page/devices-section/devices-section.component';
import { FooterSectionComponent } from '../landing-page/footer-section/footer-section.component';
import { HeaderSectionComponent } from '../landing-page/header-section/header-section.component';
import { HowToSectionComponent } from '../landing-page/how-to-section/how-to-section.component';
import { InformationSectionComponent } from '../landing-page/information-section/information-section.component';
import { ServicesSectionComponent } from '../landing-page/services-section/services-section.component';
import { EndtestChecklistSectionComponent } from './endtest-checklist-section/endtest-checklist-section.component';
import { ErrorReadoutSectionComponent } from './error-readout-section/error-readout-section.component';
import { FeedbackSectionComponent } from './feedback-section/feedback-section.component';
import { HistorySectionComponent } from './history-section/history-section.component';
import { OverviewSectionComponent } from './overview-section/overview-section.component';
import { SinglefunctiontestSectionComponent } from './singlefunctiontest-section/singlefunctiontest-section.component';
import { SupportedDevicesSectionComponent } from './supported-devices-section/supported-devices-section.component';
import { SystemRequirementsSectionComponent } from './system-requirements-section/system-requirements-section.component';

@Component({
  standalone: true,
  imports: [
    MatToolbarModule,
    MatIconModule,
    TranslateModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    CommonModule,
    DevicesSectionComponent,
    HeaderSectionComponent,
    BenefitsSectionComponent,
    CallToActionSectionComponent,
    InformationSectionComponent,
    FlexLayoutModule,
    FooterSectionComponent,
    MatSelectModule,
    ServicesSectionComponent,
    ToolbarComponent,
    HowToSectionComponent,
    RouterLink,
    OverviewSectionComponent,
    ErrorReadoutSectionComponent,
    SidenavComponent,
    SinglefunctiontestSectionComponent,
    HistorySectionComponent,
    EndtestChecklistSectionComponent,
    FeedbackSectionComponent,
    SystemRequirementsSectionComponent,
    SupportedDevicesSectionComponent,
  ],
  selector: 'app-servicetool',
  templateUrl: './servicetool.page.html',
  styleUrls: ['./servicetool.page.scss'],
})
export class ServiceToolPage {}
