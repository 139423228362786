<app-sidenav active="privacy-policy" containerClass="content-container">
  <div class="max-width-1920-inner" id="first">
    <div [innerHtml]="$T.LegalInformation | translate"></div>

    <div class="column centered-text">
      <h2 [innerHTML]="$T.PrivacyPolicyHeadline | translate"></h2>
      <p>{{ $T.PrivacyPolicyVersion | translate }}</p>
    </div>

    <h3>{{ $T.PrivacyPolicy1 | translate }}</h3>
    <p>
      {{ $T.PrivacyPolicy11 | translate | split : 0 }}
      <b>{{ $T.PrivacyPolicy11 | translate | split : 1 }}</b>
      {{ $T.PrivacyPolicy11 | translate | split : 2 }}
      <b>{{ $T.PrivacyPolicy11 | translate | split : 3 }}</b>
      <span [innerHTML]="$T.PrivacyPolicy11 | translate | split : 4"></span>
      <b>{{ $T.PrivacyPolicy11 | translate | split : 5 }}</b>
      {{ $T.PrivacyPolicy11 | translate | split : 6 }}
      <b>{{ $T.PrivacyPolicy11 | translate | split : 7 }}</b>
      {{ $T.PrivacyPolicy11 | translate | split : 8 }}
    </p>
    <p>
      {{ $T.PrivacyPolicy12 | translate | split : 0 }}
      <b>{{ $T.PrivacyPolicy12 | translate | split : 1 }}</b>
      {{ $T.PrivacyPolicy12 | translate | split : 2 }}
      <b>{{ $T.PrivacyPolicy12 | translate | split : 3 }}</b>
      {{ $T.PrivacyPolicy12 | translate | split : 4 }}
      <b>{{ $T.PrivacyPolicy12 | translate | split : 5 }}</b>
      {{ $T.PrivacyPolicy12 | translate | split : 6 }}
      <b>{{ $T.PrivacyPolicy12 | translate | split : 7 }}</b>
      {{ $T.PrivacyPolicy12 | translate | split : 8 }}
      <b>{{ $T.PrivacyPolicy12 | translate | split : 9 }}</b>
      {{ $T.PrivacyPolicy12 | translate | split : 10 }}
    </p>
    <p>{{ $T.PrivacyPolicy13 | translate }}</p>
    <p>{{ $T.PrivacyPolicy14 | translate }}</p>
    <p>{{ $T.PrivacyPolicy15 | translate }}</p>

    <div>
      <h3>{{ $T.PrivacyPolicy2 | translate }}</h3>
      <p>{{ $T.PrivacyPolicy21 | translate }}</p>
    </div>
    <div>
      <h3>{{ $T.PrivacyPolicy3 | translate }}</h3>
      <p>{{ $T.PrivacyPolicy31 | translate }}</p>
      <ul class="roman-list">
        <li>{{ $T.PrivacyPolicy31a | translate }}</li>
        <li>{{ $T.PrivacyPolicy31b | translate }}</li>
      </ul>
    </div>
    <div>
      <h3>{{ $T.PrivacyPolicy4 | translate }}</h3>
      <p>{{ $T.PrivacyPolicy41 | translate }}</p>
      <ul class="roman-list">
        <li>{{ $T.PrivacyPolicy41a | translate }}</li>
        <li>{{ $T.PrivacyPolicy41b | translate }}</li>
      </ul>
    </div>
    <div>
      <h3>{{ $T.PrivacyPolicy5 | translate }}</h3>
      <p>{{ $T.PrivacyPolicy51 | translate }}</p>
      <ul>
        <li>{{ $T.PrivacyPolicy51a | translate }}</li>
        <li>{{ $T.PrivacyPolicy51b | translate }}</li>
      </ul>
      <p>{{ $T.PrivacyPolicy52 | translate }}</p>
      <p>{{ $T.PrivacyPolicy53 | translate }}</p>
      <ul>
        <li>{{ $T.PrivacyPolicy53a | translate }}</li>
        <li>{{ $T.PrivacyPolicy53b | translate }}</li>
        <li>{{ $T.PrivacyPolicy53c | translate }}</li>
      </ul>
      <p>{{ $T.PrivacyPolicy54 | translate }}</p>
    </div>
    <div>
      <h3>{{ $T.PrivacyPolicy6 | translate }}</h3>
      <p>{{ $T.PrivacyPolicy61 | translate }}</p>
      <p>{{ $T.PrivacyPolicy62 | translate }}</p>
    </div>
    <div>
      <h3>{{ $T.PrivacyPolicy7 | translate }}</h3>
      <p>{{ $T.PrivacyPolicy71 | translate }}</p>
      <p>{{ $T.PrivacyPolicy72 | translate }}</p>
      <p>{{ $T.PrivacyPolicy73 | translate }}</p>
    </div>
    <div>
      <h3>{{ $T.PrivacyPolicy8 | translate }}</h3>
      <p>{{ $T.PrivacyPolicy81 | translate }}</p>
      <ul class="roman-list">
        <li>{{ $T.PrivacyPolicy81a | translate }}</li>
        <li>{{ $T.PrivacyPolicy81b | translate }}</li>
        <li>{{ $T.PrivacyPolicy81c | translate }}</li>
        <li>{{ $T.PrivacyPolicy81d | translate }}</li>
        <li>{{ $T.PrivacyPolicy81e | translate }}</li>
      </ul>
      <p>{{ $T.PrivacyPolicy82 | translate }}</p>
      <p>{{ $T.PrivacyPolicy83 | translate }}</p>
    </div>
    <div>
      <h3>{{ $T.PrivacyPolicy9 | translate }}</h3>
      <p>{{ $T.PrivacyPolicy91 | translate }}</p>
    </div>
    <div>
      <h3>{{ $T.PrivacyPolicy10 | translate }}</h3>
      <p>{{ $T.PrivacyPolicy101 | translate }}</p>
      <p>{{ $T.PrivacyPolicy102 | translate }}</p>
    </div>

    <div>
      <h3>{{ $T.PrivacyPolicy110 | translate }}</h3>
      <p [innerHTML]="$T.PrivacyPolicy111 | translate"></p>
      <ul>
        <li>{{ $T.PrivacyPolicy11a | translate }}</li>
        <li>{{ $T.PrivacyPolicy11b | translate }}</li>
        <li>{{ $T.PrivacyPolicy11c | translate }}</li>
        <li>{{ $T.PrivacyPolicy11d | translate }}</li>
        <li>{{ $T.PrivacyPolicy11e | translate }}</li>
        <li>{{ $T.PrivacyPolicy11f | translate }}</li>
        <li>{{ $T.PrivacyPolicy11g | translate }}</li>
      </ul>
      <p>{{ $T.PrivacyPolicy112 | translate }}</p>
      <p [innerHTML]="$T.PrivacyPolicy113 | translate"></p>
    </div>

    <div id="dr-privacynotice-div"></div>

    <div class="centered-text contact">
      <p>
        <b>W&amp;H Dentalwerk B&uuml;rmoos GmbH</b> <br />
        Ignaz-Glasner-Straße 53 <br />
        5111 B&uuml;rmoos <br />
        Austria <br />
        dataprotection&#64;wh.com
      </p>
    </div>
  </div>
</app-sidenav>
