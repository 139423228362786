<div class="max-width-1920-inner centered-text bg">
  <div class="column">
    <h2 class="white backslash-n-brake">{{ 'FutureDentistry' | translate }}</h2>
    <h4 class="backslash-n-brake white">{{ 'FutureDentistryText' | translate }}</h4>
  </div>
  <div class="mobile-column">
    <button (click)="onRegister()" class="white-bg tall" mat-flat-button>
      <mat-icon svgIcon="arrow-right"></mat-icon>
      {{ 'RegisterForFree' | translate }}
    </button>
  </div>
</div>
