<div class="max-width-1920-inner">
  <div>
    <div class="centered-text tablet-left-text mobile-left-text">
      <h2>{{ 'HistoryHeadline' | translate }}</h2>
    </div>
    <div class="row row-gap-20 tablet-column mobile-column">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            <h3>{{ 'HistoryCardHeadline1' | translate }}</h3>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div>
            <p>{{ 'HistoryCardText1' | translate }}.</p>
          </div>
          <ul>
            <li>{{ 'HistoryCardItem11' | translate }}</li>
            <li>{{ 'HistoryCardItem12' | translate }}</li>
            <li>{{ 'HistoryCardItem13' | translate }}</li>
            <li>{{ 'HistoryCardItem14' | translate }}</li>
          </ul>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            <h3>{{ 'HistoryCardHeadline2' | translate }}</h3>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div>
            <p>{{ 'HistoryCardText2' | translate }}.</p>
          </div>
          <ul>
            <li>{{ 'HistoryCardItem21' | translate }}</li>
            <li>{{ 'HistoryCardItem22' | translate }}</li>
            <li>{{ 'HistoryCardItem23' | translate }}</li>
            <li>{{ 'HistoryCardItem24' | translate }}</li>
            <li>{{ 'HistoryCardItem25' | translate }}</li>
            <li>{{ 'HistoryCardItem26' | translate }}</li>
          </ul>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
