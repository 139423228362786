<div class="max-width-1920-inner">
  <div>
    <div class="centered-text tablet-left-text mobile-left-text">
      <h2>{{ 'IntelligentAssist' | translate }}</h2>
    </div>
  </div>
  <div class="column">
    <mat-card class="flex tablet-width-50-margin-20 column">
      <mat-card-header>
        <mat-card-title>
          <h3>{{ 'RemoteUpdate' | translate }}</h3>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <ul>
          <li>{{ 'RemoteUpdateOptionEmailNotification' | translate }}</li>
          <li>{{ 'RemoteUpdateOptionChangelog' | translate }}</li>
          <li>{{ 'RemoteUpdateOptionTriggerRemote' | translate }}</li>
          <li>{{ 'RemoteUpdateOptionFeedback' | translate }}</li>
        </ul>
      </mat-card-content>
    </mat-card>
    <mat-card class="flex tablet-width-50-margin-20">
      <mat-card-header>
        <mat-card-title>
          <h3>{{ 'CustomAlarms' | translate }}</h3>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <ul>
          <li>
            {{ 'CustomAlarmsOptionConfigurableNotification' | translate }}
            <ul>
              <li>{{ 'CustomAlarmsOptionConfigurableNotificationError' | translate }}</li>
              <li>{{ 'CustomAlarmsOptionConfigurableNotificationTicket' | translate }}</li>
              <li>{{ 'CustomAlarmsOptionConfigurableNotificationConsumables' | translate }}</li>
              <li>{{ 'CustomAlarmsOptionConfigurableNotificationService' | translate }}</li>
              <li>{{ 'CustomAlarmsOptionConfigurableNotificationOffline' | translate }}</li>
            </ul>
          </li>
          <li>{{ 'CustomAlarmsOptionCustomizedAlarms' | translate }}</li>
          <li>{{ 'CustomAlarmsOptionVacuumTest' | translate }}</li>
        </ul>
      </mat-card-content>
    </mat-card>

    <mat-card class="flex tablet-width-50-margin-20">
      <mat-card-header>
        <mat-card-title>
          <h3>{{ 'ErrorDashboard' | translate }}</h3>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <ul>
          <li>{{ 'ErrorDashboardOptionFiltering' | translate }}</li>
          <li>{{ 'ErrorDashboardOptionLiveUpdate' | translate }}</li>
          <li>{{ 'ErrorDashboardOptionSolutionSuggestion' | translate }}</li>
          <li>{{ 'ErrorDashboardOptionDocumentUpload' | translate }}</li>
          <li>{{ 'ErrorDashboardOptionDownloadRemotely' | translate }}</li>
        </ul>
      </mat-card-content>
    </mat-card>
  </div>
</div>
