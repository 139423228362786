import { NgModule, Pipe, PipeTransform } from '@angular/core';

/**
 * Use a part of the splitted value
 *
 * @param value Text to be splitted
 * @param index Index of part to be returned
 * @param separator Text separator
 */
export const splitValue = (value: string, index: number, separator = '|'): string => value.split(separator)[Number(index)];

/**
 * Take only a part of a string.
 * Usually, this pipe is used in combination with the TranslatePipe in case there are links within the translated text.
 *
 * @param value - A string that has to be split up.
 * @param index - Specifies the part of the string that should be returned.
 * @param separator - Defines the separator used to split the string. Defaults to '|'.
 *
 * # Example
 *
 * ## Translation
 * ```json
 * {
 *   "CookiesText": "We use cookies. Check the |Terms of Use| here.",
 * }
 * ```
 *
 * ## Pipe Usage
 * ```html
 * <p>
 *   {{ $T.CookiesText | translate | split:0 }}
 *   <a routerLink="terms">{{ $T.CookiesText | translate | split:1 }}</a>
 *   {{ $T.CookiesText | translate | split:2 }}
 * </p>
 * ```
 *
 * ## Result
 * We use cookies. Check the _Terms of Use_ here.
 */
@Pipe({ name: 'split' })
export class SplitPipe implements PipeTransform {
  transform(value: string, index: number, separator = '|'): string {
    return splitValue(value, index, separator);
  }
}

@NgModule({
  imports: [],
  declarations: [SplitPipe],
  exports: [SplitPipe],
})
export class SplitPipeModule {}
