import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from 'ngx-flexible-layout';

@Component({
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslateModule, MatButtonModule, FlexLayoutModule],
  selector: 'app-devices-section',
  templateUrl: './devices-section.component.html',
  styleUrls: ['./devices-section.component.scss'],
})
export class DevicesSectionComponent {}
