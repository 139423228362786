<div class="max-width-1920-inner">
  <div>
    <div class="centered-text tablet-left-text mobile-left-text">
      <h2>{{ $T.EndtestChecklistHeadline | translate }}</h2>
      <p>{{ $T.EndtestChecklistText | translate }}</p>
    </div>
  </div>
  <div class="row row-gap-20 mobile-column">
    <div class="flex centered-text">
      <img alt="endtest" src="/assets/img/endtest.png" />
      <h3>{{ $T.Endtest | translate }}</h3>
    </div>
    <div class="flex centered-text">
      <img alt="validation" src="/assets/img/validation.png" />
      <h3>{{ $T.Validation | translate }}</h3>
    </div>
    <div class="flex centered-text">
      <img alt="report" src="/assets/img/report.png" />
      <h3>{{ $T.Report | translate }}</h3>
    </div>
  </div>
</div>
