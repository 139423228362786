<app-sidenav active="terms-of-use" containerClass="content-container">
  <div class="max-width-1920-inner" id="first">
    <div class="column centered-text">
      <h2 [innerHTML]="$T.TermsOfUseHeadline | translate"></h2>
      <p>{{ $T.Terms | translate }}</p>
    </div>

    <div class="terms-section">
      <h3 class="section-headline">1. {{ $T.TermsOfUse1 | translate }}</h3>
      <p>
        1.1 <span [innerHTML]="$T.TermsOfUse11 | translate | split : 0"></span>
        <a href="https://app.iodent.com">{{ $T.TermsOfUse11 | translate | split : 1 }}</a>
        {{ $T.TermsOfUse11 | translate | split : 2 }}
      </p>
      <p>1.2 {{ $T.TermsOfUse12 | translate }}</p>
      <p>1.3 {{ $T.TermsOfUse13 | translate }}</p>
      <p>1.4 {{ $T.TermsOfUse14 | translate }}</p>
      <p>1.5 {{ $T.TermsOfUse15 | translate }}</p>
    </div>
    <div>
      <h3>2. {{ $T.TermsOfUse2 | translate }}</h3>
      <p>
        2.1 {{ $T.TermsOfUse21 | translate | split : 0 }}
        <a href="https://iodent.com/scope-of-service">{{ $T.TermsOfUse21 | translate | split : 1 }}</a>
        {{ $T.TermsOfUse21 | translate | split : 2 }}
      </p>
      <p>2.2 {{ $T.TermsOfUse22 | translate }}</p>
      <p>2.3 {{ $T.TermsOfUse23 | translate }}</p>
    </div>
    <div>
      <h3>3. {{ $T.TermsOfUse3 | translate }}</h3>
      <p>
        3.1 {{ $T.TermsOfUse31 | translate | split : 0 }}
        <a href="https://iodent.com">{{ $T.TermsOfUse31 | translate | split : 1 }}</a>
        {{ $T.TermsOfUse31 | translate | split : 2 }}
      </p>
      <p>3.2 {{ $T.TermsOfUse32 | translate }}</p>
      <p>3.3 {{ $T.TermsOfUse33 | translate }}</p>
      <p>3.4 {{ $T.TermsOfUse34 | translate }}</p>
      <p>3.5 {{ $T.TermsOfUse35 | translate }}</p>
      <p>3.6 {{ $T.TermsOfUse36 | translate }}</p>
      <p>{{ $T.TermsOfUse36a | translate }}</p>
      <p>{{ $T.TermsOfUse36b | translate }}</p>
    </div>
    <div>
      <h3>4. {{ $T.TermsOfUse4 | translate }}</h3>
      <p>4.1 {{ $T.TermsOfUse41 | translate }}</p>
      <p>{{ $T.TermsOfUse41a | translate }}</p>
      <p>{{ $T.TermsOfUse41b | translate }}</p>
      <p>{{ $T.TermsOfUse41c | translate }}</p>
      <p>{{ $T.TermsOfUse41d | translate }}</p>
      <p>{{ $T.TermsOfUse41e | translate }}</p>
      <p>{{ $T.TermsOfUse41f | translate }}</p>
      <p>{{ $T.TermsOfUse41g | translate }}</p>
      <p>{{ $T.TermsOfUse41h | translate }}</p>
      <p>{{ $T.TermsOfUse41i | translate }}</p>
      <p>{{ $T.TermsOfUse41j | translate }}</p>
      <p>4.2 {{ $T.TermsOfUse42 | translate }}</p>
      <p>4.3 {{ $T.TermsOfUse43 | translate }}</p>
      <p>4.4 {{ $T.TermsOfUse44 | translate }}</p>
      <p>4.5 {{ $T.TermsOfUse45 | translate }}</p>
    </div>
    <div>
      <h3>5. {{ $T.TermsOfUse5 | translate }}</h3>
      <p>5.1 {{ $T.TermsOfUse51 | translate }}</p>
      <p>5.2 {{ $T.TermsOfUse52 | translate }}</p>
      <p>5.3 {{ $T.TermsOfUse53 | translate }}</p>
      <p>5.4 {{ $T.TermsOfUse54 | translate }}</p>
      <p>5.5 {{ $T.TermsOfUse55 | translate }}</p>
      <p>5.6 {{ $T.TermsOfUse56 | translate }}</p>
    </div>
    <div>
      <h3>6. {{ $T.TermsOfUse6 | translate }}</h3>
      <p>
        6.1 {{ $T.TermsOfUse61 | translate | split : 0 }}
        <a href="https://iodent.com">{{ $T.TermsOfUse61 | translate | split : 1 }}</a>
        {{ $T.TermsOfUse61 | translate | split : 2 }}
      </p>
      <p>6.2 {{ $T.TermsOfUse62 | translate }}</p>
    </div>
    <div>
      <h3>7. {{ $T.TermsOfUse7 | translate }}</h3>
      <p>7.1 {{ $T.TermsOfUse71 | translate }}</p>
      <p>
        7.2 {{ $T.TermsOfUse72 | translate | split : 0 }}
        <a href="https://iodent.com/scope-of-service">{{ $T.TermsOfUse72 | translate | split : 1 }}</a>
        {{ $T.TermsOfUse72 | translate | split : 2 }}
      </p>
      <p>7.3 {{ $T.TermsOfUse73 | translate }}</p>
      <p>7.4 {{ $T.TermsOfUse74 | translate }}</p>
      <p>{{ $T.TermsOfUse74a | translate }}</p>
      <p>{{ $T.TermsOfUse74b | translate }}</p>
      <p>{{ $T.TermsOfUse74c | translate }}</p>
      <p>{{ $T.TermsOfUse74d | translate }}</p>
      <p>7.5 {{ $T.TermsOfUse75 | translate }}</p>
    </div>
    <div>
      <h3>8. {{ $T.TermsOfUse8 | translate }}</h3>
      <p>8.1 {{ $T.TermsOfUse81 | translate }}</p>
      <p>8.2 {{ $T.TermsOfUse82 | translate }}</p>
      <p>8.3 {{ $T.TermsOfUse83 | translate }}</p>
      <p>8.4 {{ $T.TermsOfUse84 | translate }}</p>
      <p>8.5 {{ $T.TermsOfUse85 | translate }}</p>
    </div>
    <div>
      <h3>9. {{ $T.TermsOfUse9 | translate }}</h3>
      <p>
        9.1 {{ $T.TermsOfUse91 | translate | split : 0 }}
        <a href="https://iodent.com/privacy-policy">{{ $T.TermsOfUse91 | translate | split : 1 }}</a>
        {{ $T.TermsOfUse91 | translate | split : 2 }}
      </p>
    </div>
    <div>
      <h3>10. {{ $T.TermsOfUse10 | translate }}</h3>
      <p>10.1 {{ $T.TermsOfUse101 | translate }}</p>
      <p>10.2 {{ $T.TermsOfUse102 | translate }}</p>
      <p>10.3 {{ $T.TermsOfUse103 | translate }}</p>
      <p>10.4 {{ $T.TermsOfUse104 | translate }}</p>
      <p>10.5 {{ $T.TermsOfUse105 | translate }}</p>
      <p>10.6 {{ $T.TermsOfUse106 | translate }}</p>
    </div>
    <div>
      <h3>11. {{ $T.TermsOfUse111 | translate }}</h3>
      <p>11.1 {{ $T.TermsOfUse1111 | translate }}</p>
      <p>11.2 {{ $T.TermsOfUse1112 | translate }}</p>
    </div>
    <div>
      <h3>12. {{ $T.TermsOfUse121 | translate }}</h3>
      <p>12.1 {{ $T.TermsOfUse1211 | translate }}</p>
      <p>12.2 {{ $T.TermsOfUse1212 | translate }}</p>
      <p>12.3 {{ $T.TermsOfUse1213 | translate }}</p>
    </div>
    <div>
      <p>{{ $T.Version | translate }}</p>
    </div>
  </div>
</app-sidenav>
