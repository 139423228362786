<div class="max-width-1920-inner">
  <div>
    <div class="centered-text tablet-left-text mobile-left-text">
      <h2>{{ $T.SupportedDevices | translate }}</h2>
    </div>
    <div class="row row-gap-20 mobile-column tablet-column">
      <mat-card class="tablet-column flex">
        <mat-card-content>
          <div class="centered-text">
            <mat-icon class="icon" svgIcon="steri"></mat-icon>
            <h3>{{ $T.Sterilizer | translate }}</h3>
          </div>
          <div class="row flex mobile-column">
            <ul class="flex">
              <li>Lisa 17 (VA131 2015)</li>
              <li>Lisa 22 (VA131 2015)</li>
              <li>Lisa 17 (VA131 2019)</li>
              <li>Lisa 22 (VA131 2019)</li>
              <li>Lara 17 (RIL-1 2019)</li>
              <li>Lara 22 (RIL-1 2019)</li>
              <li>Lexa (MN-111 2018)</li>
              <li>Lina 17 (RIK-1 2020)</li>
              <li>Lina 22 (RIK-1 2020)</li>
              <li>Lyla 17 (RIN-210 2020)</li>
            </ul>
            <ul class="flex">
              <li>Lyla 22 (RIN-210 2020)</li>
              <li>Lisa 22 (VA131 2015 Med)</li>
              <li>Lexa (MN-111 2018 Med)</li>
              <li>Lara 22 (RIL-1 2019 Med)</li>
              <li>Lina 22 (RIK-1 2020 Med)</li>
              <li>Lyla 22 (RIN-210 2020 Med)</li>
              <li>Lara XL (RIS-311 2022)</li>
              <li>Lara XL (RIS-311 2022 Med)</li>
              <li>Lexa Plus (RIS-311 2022)</li>
              <li>Lexa Plus (RIS-311 2022 Med)</li>
            </ul>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="tablet-column flex">
        <mat-card-content>
          <div class="centered-text">
            <mat-icon class="icon" svgIcon="steri"></mat-icon>
            <h3>{{ $T.Implantmed | translate }}</h3>
          </div>
          <div class="row flex tablet-column">
            <ul>
              <li>Implantmed SI-1023</li>
            </ul>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
