<app-sidenav active="st-terms-of-use" containerClass="content-container">
  <div class="max-width-1920-inner" id="first">
    <div class="column centered-text">
      <h2>{{ $T.TermsOfUse | translate }}</h2>
      <p>
        {{ $T.Of | translate }}<br />
        W&H Dentalwerk Bürmoos GmbH <br />
        Ignaz-Glaser-Str. 53 <br />
        A-5111 Bürmoos <br />
        ({{ $T.Licensor | translate | split : 0 }} <b>{{ $T.Licensor | translate | split : 1 }}</b
        >)
      </p>
      <p>{{ $T.ServiceToolUsage | translate }}</p>
    </div>
    <div>
      <h3>I. {{ $T.ServiceToolTermsOfUse10 | translate }}</h3>
      <p>1. {{ $T.ServiceToolTermsOfUse11 | translate }}</p>
      <p>2. {{ $T.ServiceToolTermsOfUse12 | translate }}</p>
      <p>3. {{ $T.ServiceToolTermsOfUse13 | translate }}</p>
      <p>
        4. {{ $T.ServiceToolTermsOfUse14 | translate | split : 0 }}
        <b>{{ $T.ServiceToolTermsOfUse14 | translate | split : 1 }}</b>
        {{ $T.ServiceToolTermsOfUse14 | translate | split : 2 }}
        <b>{{ $T.ServiceToolTermsOfUse14 | translate | split : 3 }}</b>
        {{ $T.ServiceToolTermsOfUse14 | translate | split : 4 }}
      </p>
    </div>
    <div>
      <h3>II. {{ $T.ServiceToolTermsOfUse20 | translate }}</h3>
      <p>
        1. {{ $T.ServiceToolTermsOfUse21 | translate | split : 0 }}
        <b>{{ $T.ServiceToolTermsOfUse21 | translate | split : 1 }}</b>
        {{ $T.ServiceToolTermsOfUse21 | translate | split : 2 }}
        <b>{{ $T.ServiceToolTermsOfUse21 | translate | split : 3 }}</b>
        {{ $T.ServiceToolTermsOfUse21 | translate | split : 4 }}
        <b>{{ $T.ServiceToolTermsOfUse21 | translate | split : 5 }}</b>
        {{ $T.ServiceToolTermsOfUse21 | translate | split : 6 }}
      </p>
      <p>
        2. {{ $T.ServiceToolTermsOfUse22 | translate | split : 0 }}
        <b>{{ $T.ServiceToolTermsOfUse22 | translate | split : 1 }}</b>
        {{ $T.ServiceToolTermsOfUse22 | translate | split : 2 }}
      </p>
      <p>
        3. {{ $T.ServiceToolTermsOfUse23 | translate | split : 0 }}
        <a href="https://iodent.com/ST/scope-of-service">{{ $T.ServiceToolTermsOfUse23 | translate | split : 1 }}</a>
        {{ $T.ServiceToolTermsOfUse23 | translate | split : 2 }}
        <b>{{ $T.ServiceToolTermsOfUse23 | translate | split : 3 }}</b>
        {{ $T.ServiceToolTermsOfUse23 | translate | split : 4 }}
      </p>
      <p>
        4. {{ $T.ServiceToolTermsOfUse24 | translate | split : 0 }}
        <b>{{ $T.ServiceToolTermsOfUse24 | translate | split : 1 }}</b>
        {{ $T.ServiceToolTermsOfUse24 | translate | split : 2 }}
      </p>
      <p>5. {{ $T.ServiceToolTermsOfUse25 | translate }}</p>
    </div>
    <div>
      <h3>III. {{ $T.ServiceToolTermsOfUse30 | translate }}</h3>
      <p>
        1. {{ $T.ServiceToolTermsOfUse31 | translate | split : 0 }}
        <b>{{ $T.ServiceToolTermsOfUse31 | translate | split : 1 }}</b>
        {{ $T.ServiceToolTermsOfUse31 | translate | split : 2 }}
      </p>
      <p>
        2. {{ $T.ServiceToolTermsOfUse32 | translate | split : 0 }}
        <b>{{ $T.ServiceToolTermsOfUse32 | translate | split : 1 }}</b>
        {{ $T.ServiceToolTermsOfUse32 | translate | split : 2 }}
      </p>
      <p>3. {{ $T.ServiceToolTermsOfUse33 | translate }}</p>
      <p>4. {{ $T.ServiceToolTermsOfUse34 | translate }}</p>
      <p>5. {{ $T.ServiceToolTermsOfUse35 | translate }}</p>
      <p>6. {{ $T.ServiceToolTermsOfUse36 | translate }}</p>
    </div>
    <div>
      <h3>IV. {{ $T.ServiceToolTermsOfUse40 | translate }}</h3>
      <p>1. {{ $T.ServiceToolTermsOfUse41 | translate }}</p>
      <p>
        2. {{ $T.ServiceToolTermsOfUse42 | translate | split : 0 }}
        <b>{{ $T.ServiceToolTermsOfUse42 | translate | split : 1 }}</b>
        {{ $T.ServiceToolTermsOfUse42 | translate | split : 2 }}
      </p>
      <p>
        3. {{ $T.ServiceToolTermsOfUse43 | translate | split : 0 }}
        <b>{{ $T.ServiceToolTermsOfUse43 | translate | split : 1 }}</b>
        {{ $T.ServiceToolTermsOfUse43 | translate | split : 2 }}
      </p>
      <p>4. {{ $T.ServiceToolTermsOfUse44 | translate }}</p>
    </div>
    <div>
      <h3>V. {{ $T.ServiceToolTermsOfUse50 | translate }}</h3>
      <p>{{ $T.ServiceToolTermsOfUse51 | translate }}</p>
    </div>
    <div>
      <h3>VI. {{ $T.ServiceToolTermsOfUse60 | translate }}</h3>
      <p>1. {{ $T.ServiceToolTermsOfUse61 | translate }}</p>
      <p>
        2. {{ $T.ServiceToolTermsOfUse62 | translate | split : 0 }}
        <b>{{ $T.ServiceToolTermsOfUse62 | translate | split : 1 }}</b>
        {{ $T.ServiceToolTermsOfUse62 | translate | split : 2 }}
        <b>{{ $T.ServiceToolTermsOfUse62 | translate | split : 3 }}</b>
        {{ $T.ServiceToolTermsOfUse62 | translate | split : 4 }}
      </p>
      <p>3. {{ $T.ServiceToolTermsOfUse63 | translate }}</p>
      <p>4. {{ $T.ServiceToolTermsOfUse64 | translate }}</p>
    </div>
    <div>
      <h3>VII. {{ $T.ServiceToolTermsOfUse70 | translate }}</h3>
      <p>
        1. {{ $T.ServiceToolTermsOfUse71 | translate | split : 0 }}
        <a href="https://iodent.com/ST/scope-of-service">{{ $T.ServiceToolTermsOfUse71 | translate | split : 1 }}</a>
        {{ $T.ServiceToolTermsOfUse71 | translate | split : 2 }}
      </p>

      <p>2. {{ $T.ServiceToolTermsOfUse72 | translate }}</p>
      <p>
        3. {{ $T.ServiceToolTermsOfUse73 | translate | split : 0 }}
        <b>{{ $T.ServiceToolTermsOfUse73 | translate | split : 1 }}</b>
        {{ $T.ServiceToolTermsOfUse73 | translate | split : 2 }}
      </p>
      <p>
        4. {{ $T.ServiceToolTermsOfUse74 | translate | split : 0 }}
        <a href="https://iodent.com/ST/scope-of-service">{{ $T.ServiceToolTermsOfUse74 | translate | split : 1 }}</a>
        {{ $T.ServiceToolTermsOfUse74 | translate | split : 2 }}
      </p>
      <p>
        5. {{ $T.ServiceToolTermsOfUse75 | translate | split : 0 }}
        <b>{{ $T.ServiceToolTermsOfUse75 | translate | split : 1 }}</b>
        {{ $T.ServiceToolTermsOfUse75 | translate | split : 2 }}
      </p>
      <p>
        6. {{ $T.ServiceToolTermsOfUse76 | translate | split : 0 }}
        <b>{{ $T.ServiceToolTermsOfUse76 | translate | split : 1 }}</b>
        {{ $T.ServiceToolTermsOfUse76 | translate | split : 2 }}
      </p>
      <p>7. {{ $T.ServiceToolTermsOfUse77 | translate }}</p>
    </div>
    <div>
      <h3>VIII. {{ $T.ServiceToolTermsOfUse80 | translate }}</h3>
      <p>1. {{ $T.ServiceToolTermsOfUse81 | translate }}</p>
      <p>2. {{ $T.ServiceToolTermsOfUse82 | translate }}</p>
      <p>
        3. {{ $T.ServiceToolTermsOfUse83 | translate | split : 0 }}
        <b>{{ $T.ServiceToolTermsOfUse83 | translate | split : 1 }}</b>
        {{ $T.ServiceToolTermsOfUse83 | translate | split : 2 }}
      </p>
      <p>4. {{ $T.ServiceToolTermsOfUse84 | translate }}</p>
    </div>
    <div>
      <h3>IX. {{ $T.ServiceToolTermsOfUse90 | translate }}</h3>
      <p>1. {{ $T.ServiceToolTermsOfUse91 | translate }}</p>
      <p>2. {{ $T.ServiceToolTermsOfUse92 | translate }}</p>
    </div>
    <div>
      <h3>X. {{ $T.ServiceToolTermsOfUse100 | translate }}</h3>
      <p>{{ $T.ServiceToolTermsOfUse101 | translate }}</p>
    </div>
    <div>
      <h3>XI. {{ $T.ServiceToolTermsOfUse110 | translate }}</h3>
      <p>1. {{ $T.ServiceToolTermsOfUse111 | translate }}</p>
      <p>2. {{ $T.ServiceToolTermsOfUse112 | translate }}</p>
      <p>3. {{ $T.ServiceToolTermsOfUse113 | translate }}</p>
      <p>4. {{ $T.ServiceToolTermsOfUse114 | translate }}</p>
      <p>5. {{ $T.ServiceToolTermsOfUse115 | translate }}</p>
      <p>6. {{ $T.ServiceToolTermsOfUse116 | translate }}</p>
      <p>7. {{ $T.ServiceToolTermsOfUse117 | translate }}</p>
      <p>8. {{ $T.ServiceToolTermsOfUse118 | translate }}</p>
    </div>
    <div class="centered-text version">
      <p>{{ $T.VersionToUServiceTool | translate }}</p>
    </div>
  </div>
</app-sidenav>
