<div class="max-width-1920-inner">
  <div>
    <div class="centered-text">
      <h2>{{ $T.ServicesHeadline | translate }}</h2>
      <h4 class="backslash-n-brake">{{ $T.ServicesText | translate }}</h4>
    </div>

    <div class="row row-gap-20 mobile-column tablet-wrap tablet-wrap-margin-20">
      <mat-card class="tablet-width-50-margin-20 centered-text column" appearance="outlined">
        <div class="flex">
          <img alt="IntelligentAssist" src="/assets/img/intelligent-assistant.jpg" />
        </div>
        <div class="flex">
          <h3>{{ $T.IntelligentAssist | translate }}</h3>
          <p [innerHTML]="$T.IntelligentAssistText | translate"></p>
        </div>
      </mat-card>

      <mat-card class="tablet-width-50-margin-20 centered-text" appearance="outlined">
        <div class="flex">
          <img alt="WuHSterilizers" src="/assets/img/wh-sterilisatoren.jpg" />
        </div>
        <div class="flex">
          <h3>{{ $T.WuHSterilizers | translate }}</h3>
          <p [innerHTML]="$T.WuHSteriText | translate"></p>
        </div>
      </mat-card>

      <mat-card class="tablet-width-50-margin-20 centered-text" appearance="outlined">
        <div class="flex">
          <img alt="ImplantmedPlus" src="/assets/img/implantmed-plus.jpg" />
        </div>
        <div class="flex">
          <h3>{{ $T.ImplantmedPlus | translate }}</h3>
          <p [innerHTML]="$T.ImplantmedPlusText | translate"></p>
        </div>
      </mat-card>
    </div>
  </div>
</div>
